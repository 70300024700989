<article class="h-full flex flex-col">
  <div class="flex items-center justify-between my-3 px-6">
    <span class="heading-4 bold truncate">{{ title }}</span>
    <img
      [src]="close_icon"
      alt="close-btn"
      mat-dialog-close
      class="cursor-pointer close-btn"
    />
  </div>

  <hr class="border" />

  <!-- SideBar Option List -->
  <section class="pagination-scroller-div no-scrollbar px-6 py-2">
    <div class="flex flex-col option-list">
      <!-- ! PAID BATCHES -->
      <div class="mt-3" *ngIf="paidBatchList.length > 0">
        <div class="mb-2">
          <span class="body-1 semibold">Paid Batches</span>
        </div>

        <div
          *ngFor="let item of paidBatchList"
          class="flex items-center justify-between cursor-pointer option-section px-2.5 py-3.5"
          (click)="handleSelectedItem(item)"
          [ngClass]="{
            'selected-option': item.batch._id === selectedItem.batch._id
          }"
        >
          <span class="body-2 medium option-text truncate flex-1">{{
            item.batch.name
          }}</span>

          <span
            class="mahapack-tag ml-4 mx-2 caption-2 semibold"
            *ngIf="item.mahapackBatch.name.length > 0"
          >
            {{ item.mahapackBatch.name }}
          </span>
          <input
            type="checkbox"
            class="inp-checkbox"
            [checked]="item.batch._id === selectedItem.batch._id"
          />
        </div>
      </div>

      <!-- ! FREE BATCHES -->
      <div class="mt-3" *ngIf="freeBatchList.length > 0">
        <div class="mb-2">
          <span class="body-1 semibold">Free Batches</span>
        </div>

        <div
          *ngFor="let item of freeBatchList"
          class="flex items-center justify-between cursor-pointer option-section px-2.5 py-3.5 mb-2"
          (click)="handleSelectedItem(item)"
          [ngClass]="{
            'selected-option': item.batch._id === selectedItem.batch._id
          }"
        >
          <div class="flex items-center truncate name-div">
            <span class="body-2 medium option-text truncate flex-1">{{
              item.batch.name
            }}</span>

            <span
              class="mahapack-tag ml-4 caption-2 semibold"
              *ngIf="item.mahapackBatch.name.length > 0"
            >
              {{ item.mahapackBatch.name }}
            </span>
          </div>

          <input
            type="checkbox"
            class="inp-checkbox"
            [checked]="item.batch._id === selectedItem.batch._id"
          />
        </div>
      </div>

      <!-- LOAD MORE BUTTON -->
      <div class="flex items-center justify-center mt-3">
        <app-load-more
          *ngIf="data.paginate && paginationEnabled"
          (loadMore)="loadmoreContent()"
        ></app-load-more>
      </div>
    </div>
  </section>
</article>
