import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter, lastValueFrom, skip, Subscription } from 'rxjs';
import {
  StreakInfoType,
  StreakService,
} from 'src/app/common/services/streak/streak.service';
import { StreakComponent } from '../streak-component';
import {
  CONFETTI_LOTTIE,
  STREAK_FIRE_ICON,
  STREAK_FIRE_LOTTIE,
  STREAK_RED_FIRE_ICON,
  STREAK_RED_ICON,
  STREAK_RED_TRIANGLE,
  STREAK_WOOD_FIRE_ICON,
} from 'src/app/core/assets.location';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-streak-header-button',
  templateUrl: './streak-header-button.component.html',
  styleUrls: ['./streak-header-button.component.scss'],
})
export class StreakHeaderButtonComponent implements OnInit {
  streakInfoData: StreakInfoType;
  showStreakAnimation = false;
  streakFireImg = STREAK_FIRE_ICON;
  streakRedFireImg = STREAK_RED_FIRE_ICON;

  streakRedTriangle: AnimationOptions = {
    path: STREAK_RED_TRIANGLE,
    loop: false,
  };
  streakWoodFireIcon = STREAK_WOOD_FIRE_ICON;
  streakRedIcon = STREAK_RED_ICON;
  fireLottie: AnimationOptions = {
    path: STREAK_FIRE_LOTTIE,
  };
  confettiLottie: AnimationOptions = {
    path: CONFETTI_LOTTIE,
  };
  showStreakInfoData: any;
  routerEventsSubscription: Subscription;

  percentage: number = 100;
  isNumberChanged: boolean = false;
  isFireLottie: boolean = false;
  showStreakCompletionAnimation = false;

  constructor(
    public router: Router,
    public streakService: StreakService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.router.url.endsWith('/study')) {
      this.getStreakInfo();
    }
    this.routerEventsSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        skip(1)
      )
      .subscribe((event: any) => {
        if (this.router.url.endsWith('/study')) {
          this.getStreakInfo();
        }
      });
  }

  async getStreakInfo() {
    try {
      this.showStreakInfoData = await lastValueFrom(
        this.streakService.getShowStreakInfo()
      );

      if (!this.showStreakInfoData?.showStreak) {
        return;
      }

      this.streakInfoData = await lastValueFrom(
        this.streakService.getStreakInfo()
      );

      if (this.streakInfoData?.todaysWatchTime < 10) {
        localStorage.removeItem('streak_completion_modal_viewed');
        localStorage.removeItem('streak_completion_animation_viewed');
      }
      if (this.streakInfoData?.isOnGrace === false) {
        localStorage.removeItem('streak_grace_modal_viewed');
      }

      const isStreakIntroductionModalViewed = JSON.parse(
        localStorage.getItem('streak_introduction_modal_viewed') || 'false'
      );
      const isStreakGraceModalViewed = JSON.parse(
        localStorage.getItem('streak_grace_modal_viewed') || 'false'
      );
      const isStreakCompletionModalViewed = JSON.parse(
        localStorage.getItem('streak_completion_modal_viewed') || 'false'
      );

      const isStreakCompletionAnimationViewed = JSON.parse(
        localStorage.getItem('streak_completion_animation_viewed') || 'false'
      );

      if (
        typeof window !== 'undefined' &&
        this.streakInfoData?.firstTimeUser === false
      ) {
        localStorage.setItem('streak_first_time_user', 'false');
      }
      if (!isStreakCompletionAnimationViewed && isStreakCompletionModalViewed) {
        this.toggleStreakAnimationComponent();
      }
      if (
        this.streakInfoData?.firstTimeUser === true &&
        isStreakIntroductionModalViewed === false
      ) {
        this.onStreakClick(this.streakInfoData);
      } else if (
        this.streakInfoData?.todaysWatchTime >= 10 &&
        isStreakCompletionModalViewed === false
      ) {
        this.onStreakClick(this.streakInfoData, true);
      } else if (
        this.streakInfoData?.graceTimeLeft > 0 &&
        this.streakInfoData?.graceTimeLeft <= 24 &&
        this.streakInfoData?.isOnGrace &&
        isStreakGraceModalViewed === false &&
        this.streakInfoData?.graceModalCount < 5
      ) {
        this.onStreakClick(this.streakInfoData, true);
      }
    } catch (e) {
      console.log(e);
    }
  }
  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  onStreakClick(data: StreakInfoType, showGraceModal?: boolean) {
    const updatedData = showGraceModal
      ? { ...data, showGraceModal }
      : { ...data };

    const dialogRef = this.dialog.open(StreakComponent, {
      panelClass: 'streak-modal',
      data: updatedData,
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.componentInstance.closeStreakEvent.subscribe(() => {
      this.toggleStreakAnimationComponent();
    });
  }

  getGraceTimeAlert() {
    return (
      this.streakInfoData?.graceTimeLeft <= 12 &&
      this.streakInfoData?.isOnGrace === true &&
      this.streakInfoData?.todaysWatchTime < 10
    );
  }

  getStreakFillStyle(totalWatchTime: number) {
    const percentage = Math.min(totalWatchTime * 10, 100);
    const angle = percentage * 3.6;

    const backgroundColor = totalWatchTime >= 10 ? '#FFF7E6' : '#EFEFEF';
    const radiusColor = totalWatchTime >= 10 ? '#1B7938' : '#5a4bda';

    return {
      backgroundColor: backgroundColor,
      backgroundImage: `conic-gradient(#FFF6E5 ${angle}deg, #EFEFEF ${angle}deg 360deg)`,
      backgroundClip: 'border-box',
      radius: `conic-gradient(${radiusColor} ${angle}deg, #EFEFEF ${angle}deg 360deg)`,
    };
  }

  toggleStreakAnimationComponent() {
    if (this.streakInfoData?.todaysWatchTime >= 10) {
      this.showStreakCompletionAnimation = true;
      if (!this.isNumberChanged) {
        setTimeout(() => {
          this.isNumberChanged = true;
        }, 3000);
      }

      if (!this.isFireLottie) {
        setTimeout(() => {
          this.isFireLottie = true;
        }, 500);
      }
      setTimeout(() => {
        this.showStreakCompletionAnimation = false;
      }, 5000);
      localStorage.setItem('streak_completion_animation_viewed', 'true');
    } else {
      this.showStreakAnimation = true;

      setTimeout(() => {
        this.showStreakAnimation = false;
      }, 3000);
    }
  }
}
