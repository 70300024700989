<ng-container *ngIf="showXPButton">
  <div
    class="min-w-[80px] mr-[10px] xp-button relative"
    (click)="handleDrawerOpen()"
  >
    <div
      class="flex items-center justify-center gap-[10px] rounded-[30px] h-[100%] p-1 xp-container px-2"
      #xpHeaderButton
    >
      <img [src]="xpIcon" height="28px" width="24px" #xpGainImage />
      <span class="text-[18px] font-[700] weekly-xp-text">{{
        showCurrentXp?.weeklyTotalXP
      }}</span>
    </div>
  </div>
  <div class="container" *ngIf="showToolTip">
    <div class="tool-tip">
      <div>
        <p class="font-[600] text-[18px] pt-2">Leaderboard Unlocked!</p>
      </div>
      <p class="tool-tip-text">
        Great news! You've unlocked the leaderboard! Now<br />
        earn XP, compete with classmates, and climb up<br />
        the leagues. Let the challenge begin!
      </p>
      <div class="check-button" (click)="dismissToolTip('openSidebar')">
        Check it out
      </div>
    </div>
  </div>
  <div *ngIf="showAnimation">
    <xp-gain-box
      [earnedXp]="earnedXp"
      [isFromVideo]="isFromVideo"
    ></xp-gain-box>
  </div>
</ng-container>
