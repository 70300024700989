import { BLOCKED } from 'src/app/core/assets.location';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import {
  STORAGE_ENUM,
  StorageService,
} from 'src/app/common/services/storage/storage.service';
import { StudyFunctionalityService } from 'src/app/common/services/study-functionality/study-functionality.service';
import {
  Placeholder,
  StudyService,
} from 'src/app/common/services/study/study.service';
import {
  CALENDAR_NEW_IMG,
  STUDY_ARROW_DOWN,
  STUDY_ARROW_UP,
  mpLeftIcon,
  mpRightIcon,
} from 'src/app/core/assets.location';
import { WIDGET_CLICK } from 'src/app/core/analytics-events';
import {
  AllPurchasedBatches,
  BatchDetailModel,
  DayCatagory,
  TodayScheduleModel,
} from '../../../batch-overview/batch-overview.model';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component';
import { PAGINATION_LIMIT } from 'src/app/constants/app.constant';
import { isAfter, isBefore } from 'date-fns';
import {
  ContentService,
  RatingModel,
} from 'src/app/common/services/content/content.service';
import { ScheduleDropdownVariant } from 'src/app/enum/study.enum';
import { STUDY_PAGE_EVENT } from 'src/app/core/analytics-events';
import { PartPaymentService } from 'src/app/common/services/part-payment/part-payment.service';
import { UpcomingInstalmentData } from 'src/app/common/services/batch/batch.modal';
import { UnleashApiPayloadType } from 'src/app/common/services/cohort/cohort.service';
import { UNLEASH_PAYLOAD_ENUM } from 'src/app/constants/unleash.constant';

@Component({
  selector: 'app-schedule-class',
  templateUrl: './schedule-class.component.html',
  styleUrls: ['./schedule-class.component.scss'],
  providers: [StudyService],
})
export class ScheduleClassComponent implements OnInit, OnDestroy {
  @ViewChild('dayModal', { read: ElementRef })
  dayModal!: ElementRef<any>;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() widget_type: string;
  @Input() widgetId: string;
  blocked = BLOCKED;
  selectDay = 'Today';
  today = new Date().toISOString().slice(0, 10);
  dropdownVariant = ScheduleDropdownVariant;
  @Output('eventsData') eventsData: EventEmitter<{
    batch: string;
    batchType: string;
    videoCount: number;
  }> = new EventEmitter<{
    batch: string;
    batchType: string;
    videoCount: number;
  }>();
  selectedBatch: AllPurchasedBatches;
  todayScheduleList: Array<TodayScheduleModel> = [];
  query: any;
  processing: boolean;
  paginate: boolean = true;
  batchList: Array<AllPurchasedBatches> = [];
  subProcessing: boolean;
  subPaginate: boolean;
  subquery: any;
  batchId: string;
  batchListSubs: Subscription;
  batchDetailsSub: Subscription;
  batchDetail: BatchDetailModel;
  isModalOpen: boolean = false;
  arrow_up_IMG = STUDY_ARROW_UP;
  arrow_down_img = STUDY_ARROW_DOWN;
  calendarImg = CALENDAR_NEW_IMG;
  defaultHeading =
    '<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /></head><body><div style="font-size: 24px !important; font-weight: 500 !important;"><span style="font-weight: 700 !important;">Scheduled Classes</span> </div></body></html>';

  selectedDay: DayCatagory;
  dayCatagory: DayCatagory[] = [
    { name: 'Yesterday', display_order: 1 },
    { name: 'Today', display_order: 2 },
    { name: 'Tomorrow', display_order: 3 },
  ];
  batchlistPaginate: boolean = false;
  batchlistQuery: { page: number; mode: number; sort: string };
  day = Day;
  statsList: any[] = [];
  userSubs: Subscription;
  userInfo: any;
  leftIcon = mpLeftIcon;
  rightIcon = mpRightIcon;
  pageName: string = 'study_page';
  @ViewChild('scrollContainer', { read: ElementRef })
  scrollContainer!: ElementRef<any>;
  upcomingInstalment = new UpcomingInstalmentData({});
  @Output('selectedBatchName') selectedBatchName = new EventEmitter<any>();
  constructor(
    private batchService: BatchService,
    private errorService: ShowErrorService,
    private globalService: GlobalService,
    private router: Router,
    private studyFunService: StudyFunctionalityService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private localStorageService: StorageService,
    private scheduleDialog: NgDialogAnimationService,
    private contentService: ContentService,
    private _partPaymentService: PartPaymentService,
    private storageService: StorageService
  ) {
    this.selectedBatch = new AllPurchasedBatches({});
    this.getUser();
    this.query = {
      page: 1,
      mode: 1,
      sort: SortBy.TAG_LIST,
    };
    this.batchlistQuery = {
      page: 1,
      mode: 1,
      sort: SortBy.TAG_LIST,
    };
    this.selectedDay = this.dayCatagory[1];
    this.getBatchList();
  }
  async ngOnInit() {}

  ngOnDestroy(): void {
    if (this.batchListSubs) this.batchListSubs.unsubscribe();
    if (this.batchDetailsSub) this.batchDetailsSub.unsubscribe();
    this.userSubs?.unsubscribe();
  }

  async getBatchList() {
    if (this.batchlistQuery.page === 1) {
      this.batchlistPaginate = true;
      this.processing = true;
      this.batchList.splice(0);
    }

    try {
      const res = await lastValueFrom(
        this.batchService.getAllPurchasedBatches(this.batchlistQuery)
      );
      console.log('Response from getAllPurchasedBatches:', res);
      let list: Array<AllPurchasedBatches> = [];
      if (res) {
        this.batchlistPaginate = res.length === PAGINATION_LIMIT;
        list = res.map((item: any) => new AllPurchasedBatches(item));
      } else {
        this.processing = false;
      }
      this.batchList = [...this.batchList, ...list];
    } catch (error) {
      this.errorService.showError(error);
    } finally {
      this.processing = false;

      if (this.batchList.length > 0) {
        await this.getSelectedBatch();
        await this.fetchBatchDetails();
        await this.getTodaySchedule();
        this.subquery = {
          ut: Math.floor(Date.now() / 1000),
          date: this.today,
        };
        this.studyFunService.setBatchList(this.batchList);
      }
    }
  }

  getOrderedLectures(lectureList: Array<TodayScheduleModel>) {
    let liveLectures: TodayScheduleModel[] = [];
    let upcommingLectures: TodayScheduleModel[] = [];
    let endedLectures: TodayScheduleModel[] = [];
    lectureList.forEach((lectureData: TodayScheduleModel) => {
      const startTime = new Date(lectureData.startTime);
      const endTime = new Date(lectureData.endTime);
      const now = new Date();

      if (isAfter(now, startTime) && isBefore(now, endTime)) {
        // live
        liveLectures.push(lectureData);
      } else if (isAfter(now, endTime)) {
        // endLive
        endedLectures.push(lectureData);
      } else {
        // upcomming
        upcommingLectures.push(lectureData);
      }
    });

    this.todayScheduleList = [
      ...liveLectures,
      ...upcommingLectures,
      ...endedLectures,
    ];
  }

  async getTodaySchedule() {
    this.localStorageService.setScheduleClassBatch(this.selectedBatch);
    this.subProcessing = true;
    this.subPaginate = true;
    this.todayScheduleList.splice(0);
    this.batchId = this.selectedBatch.batch._id;

    try {
      const res = await lastValueFrom(
        this.batchService.getTodaysStudySchedule(this.batchId, this.subquery)
      );
      let list: Array<TodayScheduleModel> = [];
      if (res) {
        if (res.length < 20) {
          this.paginate = false;
        }
        list = res
          .map((item: any) => new TodayScheduleModel(item))
          ?.filter(
            (item: TodayScheduleModel) =>
              !(
                item?.homeworkIds?.length > 0 &&
                !item?.videoDetails?.videoUrl &&
                !item?.videoDetails?.embedCode &&
                !item?.videoDetails?.hls_url &&
                !item?.url
              )
          )
          ?.filter(
            (item: TodayScheduleModel) =>
              !(item?.isDPPNotes || item?.isDPPVideos)
          );
      } else {
        this.processing = false;
      }
      let lectureList = [...this.todayScheduleList, ...list];

      // type id for rating of each video
      const typeIds = lectureList
        ?.map((item: TodayScheduleModel) => item._id)
        .toString();

      // for stats typeids of ended class
      const statsTypeIds = lectureList
        ?.filter(
          (item: TodayScheduleModel) =>
            isAfter(new Date(), new Date(item.endTime)) &&
            item.status !== 'CANCELED'
        )
        ?.map((item: TodayScheduleModel) => item._id)
        .toString();

      // for teacher their userids
      const userIds = lectureList
        .map((item: TodayScheduleModel) => item?.teachers[0])
        ?.filter((res: string) => res?.length > 0 && res)
        .toString();

      if (statsTypeIds?.length) {
        this.statsList = await this.getWatchedVideoStats(statsTypeIds);
      }
      const myRatings: RatingModel[] = await this.getMyRatings({ typeIds });
      const userDetails = await this.getUserDetails({ userIds });

      if (myRatings?.length || this.statsList?.length || userDetails?.length) {
        const ratingList = lectureList?.map((item: TodayScheduleModel) => {
          // rating
          const res = myRatings?.filter(
            (r: RatingModel) => item._id === r.typeId
          );
          item.rating = res && parseInt(res[0]?.rating || '0');
          item.feedback = (res && res[0]?.feedback) || '';

          // progess
          const stats = this.statsList?.filter(
            (e: any) => e.typeId === item._id
          )[0];
          item.lastWatchedPointInSec = stats?.lastWatchedPointInSec || 0;

          // teacher
          const teacher = userDetails?.filter(
            (r: any) => item?.teachers[0] === r._id
          );
          item.teacherImage = teacher && teacher[0]?.imageId;
          item.teacherName = (teacher && teacher[0]?.name) || '';
          return item;
        });
        lectureList = [...ratingList];
      }
      this.todayScheduleList = lectureList;
      // this.getOrderedLectures(lectureList);
    } catch (e) {
      this.errorService.showError(e);
    } finally {
      this.subProcessing = false;
      this.eventsData.emit({
        batch: this.selectedBatch.batch.name,
        batchType: this.selectedBatch.isPaid ? 'paid' : 'free',
        videoCount: this.todayScheduleList.length,
      });
    }
  }

  // dayChange(data: string) {
  //   data = data.toLowerCase();
  //   this.selectDay = data;
  //   const gaData = {
  //     day: data,
  //   };

  //   this.firebaseAnalytics.logEvents(STUDY_CALENDER, gaData, true);

  //   if (this.selectedBatch) {
  //     if (data === Day.YESTERDAY) {
  //       let yesterdayDate = new Date();
  //       yesterdayDate.setDate(new Date().getDate() - 1);
  //       this.subquery.date = yesterdayDate.toISOString().slice(0, 10);
  //       this.getTodaySchedule();
  //     }
  //     if (data === Day.TODAY) {
  //       this.subquery.date = this.today;
  //       this.getTodaySchedule();
  //     }
  //     if (data === Day.TOMORROW) {
  //       let tomorrowDate = new Date();
  //       tomorrowDate.setDate(new Date().getDate() + 1);
  //       this.subquery.date = tomorrowDate.toISOString().slice(0, 10);
  //       this.getTodaySchedule();
  //     }
  //   } else {
  //     this.globalService.showSnackBar(
  //       `Please select a batch for ${data} schedule `,
  //       'ok'
  //     );
  //   }
  // }
  contactUs() {
    this.router.navigate(['contactus']);
  }

  exploreBatches() {
    this.router.navigate([`batches`]);
  }

  async getSelectedBatch() {
    const scheduleBatch =
      this.localStorageService.getScheduleClassBatch() ||
      new AllPurchasedBatches({});

    if (scheduleBatch.batch._id.length > 0) {
      this.selectedBatch =
        this.batchList.filter(
          (batch: AllPurchasedBatches) =>
            batch.batch._id === scheduleBatch.batch._id
        )[0] || this.batchList[0];
    } else {
      this.selectedBatch = this.batchList[0];
    }
    this.selectedBatchName.emit({
      batchName: this.selectedBatch.batch.name,
      isPaid: this.selectedBatch.isPaid,
    });
  }

  async getUpcomingInstalment(batchId: string) {
    try {
      const res = await lastValueFrom(
        this._partPaymentService.getUpcomingInstalment(batchId)
      );
      if (res) {
        this.upcomingInstalment = new UpcomingInstalmentData(res);
      } else {
        this.upcomingInstalment = new UpcomingInstalmentData({});
      }
    } catch (e) {
      console.error(e);
    }
  }

  async fetchBatchDetails() {
    this.batchDetailsSub = this.batchService
      .getBatchDetails(this.selectedBatch?.batch._id)
      .subscribe(async (res) => {
        if (res) {
          this.batchDetail = res;
          this.batchService.setBatchDetails(res);
          this.batchService.setBatchData(res);
          if (res?.isPurchased) {
            await this.getUpcomingInstalment(res?._id);
          }
        }
      });
  }

  // enhancement
  selectDropdown(cameFrom: string) {
    const dialogRef = this.scheduleDialog.open(ScheduleDialogComponent, {
      panelClass: 'schedule-dialog',
      position: { right: '0px' },
      disableClose: false,
      data: {
        title:
          cameFrom === CameFrom.BATCH ? 'Select a Batch' : 'Select an Option',
        itemList:
          cameFrom === CameFrom.BATCH ? this.batchList : this.dayCatagory,
        selectedItem:
          cameFrom === CameFrom.BATCH ? this.selectedBatch : this.selectedDay,
        key:
          cameFrom === CameFrom.BATCH
            ? ScheduleKey.SLUG
            : ScheduleKey.DISPLAY_ORDER,
        paginationEnabled: cameFrom === CameFrom.BATCH,
        paginate: cameFrom === CameFrom.BATCH ? this.batchlistPaginate : false,
      },
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 400 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 400 },
        },
      },
    });

    dialogRef.componentInstance.handleLoadMore.subscribe(async (paginate) => {
      if (paginate) {
        await this.loadMoreBatchListData();
        dialogRef.componentInstance.itemList = this.batchList;
        dialogRef.componentInstance.paginate = this.batchlistPaginate;
      } else {
        dialogRef.componentInstance.paginate = this.batchlistPaginate;
        dialogRef.componentInstance.paginationEnabled = false;
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result['data']) {
        if (cameFrom === CameFrom.BATCH) {
          this.logBatchSectionChangeEvent(result.data, 'batch_select');
          this.selectedBatch = result.data;
          this.fetchBatchDetails();
          this.getTodaySchedule();
          this.logStudyEvent(this.selectedBatch.batch.name);
        } else {
          this.selectedDay = result.data;
          // this.dayChange(this.selectedDay.name);
          this.logStudyEvent(this.selectedDay.name);
        }
      } else {
        this.logBatchSectionChangeEvent(undefined, 'cancel');
      }
    });
  }

  logBatchSectionChangeEvent(newBatch: any, action: string) {
    const data = {
      pre_selected_batch: this.selectedBatch.batch.name,
      pre_selected_type: this.selectedBatch.isPaid ? 'paid' : 'free',
      new_selected_batch: (newBatch && newBatch?.batch?.name) || '',
      new_selected_type:
        (newBatch && (newBatch && newBatch?.isPaid ? 'paid' : 'free')) || '',
      action,
    };
    this.firebaseAnalytics.logEvents(
      STUDY_PAGE_EVENT.BATCH_CHANGE_ACTION,
      data,
      true,
      true,
      true,
      true
    );
  }

  async loadMoreBatchListData() {
    if (this.batchlistPaginate) {
      this.batchlistQuery.page++;
      await this.getBatchList();
    }
  }

  logStudyEvent(url: string): void {
    const data = {
      widgetid: this.widgetId,
      widget_type: this.widget_type,
      widget_redirection: url,
      placeholder: Placeholder.STUDY_PAGE,
    };
    this.firebaseAnalytics.logEvents(
      WIDGET_CLICK,
      data,
      false,
      true,
      true,
      true,
      true
    );
  }

  async getMyRatings(data: any) {
    if (data?.typeIds?.length === 0) return [];
    try {
      const res = await lastValueFrom(
        this.contentService.getMyRatingByIds(data)
      );
      return res;
    } catch (error) {
      this.errorService.showError(error);
      return [];
    }
  }

  async getUserDetails(data: any) {
    if (data?.userIds?.length === 0) return null;
    try {
      const res = await lastValueFrom(
        this.contentService.getUserDetailsList(data)
      );
      return res?.data;
    } catch (error) {
      this.errorService.showError(error);
      return null;
    }
  }

  getUser() {
    this.userSubs = this.globalService.getUser().subscribe((res) => {
      if (res) {
        this.userInfo = res;
      }
    });
  }

  async getWatchedVideoStats(typeIds: string) {
    const query = {
      typeId: typeIds,
      userId: this.userInfo?.id || '',
    };
    if (!query.typeId || !query.userId) return [];
    try {
      const res = await lastValueFrom(
        this.batchService.getRecentlyWatchedVideoStats(query)
      );
      if (res && res['data'].length > 0) {
        return res['data'];
      } else {
        return [];
      }
    } catch (e) {
      this.errorService.showError(e);
      return [];
    }
  }

  shouldPopUpVisible() {
    if (
      this.batchDetail?.isPurchased &&
      this._partPaymentService.shouldLockedForPartPayment(
        this.batchDetail?.config
      )
    ) {
      this._partPaymentService.handleBatchPopup(
        this.batchDetail,
        this.upcomingInstalment,
        this.pageName
      );
      return true;
    }
    return false;
  }

  async handleWeeklySchedule() {
    if (this.shouldPopUpVisible()) {
      return;
    }

    this.batchDetailsSub = this.batchService
      .getBatchDetails(this.selectedBatch?.batch._id)
      .subscribe((res) => {
        if (res) {
          this.batchService.setBatchData(res);
          this.router.navigate(
            [`batches/${this.selectedBatch?.batch._id}/subject-weekly`],
            {
              queryParams: {
                came_from: 'study',
                week: 'current',
              },
            }
          );
        }
      });
    this.logWeeklyScheduleClick();
  }

  logWeeklyScheduleClick() {
    const data = {
      batch_selected: this.selectedBatch.batch.name,
      landed_on: 'current_week',
    };
    this.firebaseAnalytics.logEvents(
      STUDY_PAGE_EVENT.WEEKLY_SCHEDULE_CLICK,
      data,
      false,
      true,
      true,
      true
    );
  }

  handleVideoClickEvent(lectureData: TodayScheduleModel) {
    const data = {
      batch_selected: this.selectedBatch.batch.name,
      batch_id: this.selectedBatch.batch._id,
      video_id: lectureData.videoDetails._id || lectureData._id || '',
      video_type: lectureData?.isLive
        ? 'live'
        : lectureData?.tag?.toLowerCase().includes('upcoming')
        ? 'upcoming'
        : 'ended',
      schedule_id: lectureData._id,
      video_section: 'todays_class',
    };
    this.firebaseAnalytics.logEvents(
      STUDY_PAGE_EVENT.VIDEO_CLICK,
      data,
      true,
      true,
      true,
      true
    );
  }

  handleAllClassClickEvent() {
    this.firebaseAnalytics.logEvents(
      STUDY_PAGE_EVENT.VIEW_ALL_CLASSES,
      {
        batch_selected: this.selectedBatch.batch.name || '',
      },
      true,
      true,
      true,
      true
    );
  }

  async getCohortConfig() {
    return JSON.parse(localStorage.getItem('UserCohortConfig') || '{}');
  }

  async handleAllClasses() {
    const renderNewBatchlist =
      this.globalService.batchRevampUnleash$.getValue();

    if (this.shouldPopUpVisible()) {
      return;
    }

    const cohortConfig: any = await this.getCohortConfig();
    this.handleAllClassClickEvent();
    if (this.selectedBatch.batch?.masterBatchId) {
      const premiumData = {
        slug: this.selectedBatch?.batch._id,
        isPremium: true,
      };
      this.localStorageService.setPremiumData(premiumData);
      if (renderNewBatchlist) {
        this.storageService.setSessionValue(
          STORAGE_ENUM.BATCH_SOURCE_URL,
          this.router.url,
          'string'
        );
        this.router.navigate([`/batches`], {
          queryParams: {
            batchChildUrl: `/batches-new/batches/${this.selectedBatch?.batch._id}/premium-batch-overview/all-classes?came_from=study`,
          },
        });
      } else {
        this.router.navigate(
          [
            `batches/${this.selectedBatch?.batch._id}/premium-batch-overview/all-classes`,
          ],
          {
            queryParams: {
              came_from: 'study',
            },
          }
        );
      }
      return;
    }

    if (renderNewBatchlist) {
      this.storageService.setSessionValue(
        STORAGE_ENUM.BATCH_SOURCE_URL,
        this.router.url,
        'string'
      );
      this.router.navigate([`/batches`], {
        queryParams: {
          batchChildUrl: `/batches-new/batches/${this.selectedBatch?.batch._id}/batch-overview?came_from=study`,
        },
      });
    } else {
      this.router.navigate(
        [`batches/${this.selectedBatch?.batch._id}/batch-overview`],
        {
          queryParams: {
            came_from: 'study',
            activeSection:
              (cohortConfig &&
                cohortConfig.batchTitles &&
                cohortConfig.batchTitles.allClasses) ||
              'All Classes',
          },
        }
      );
    }
  }

  showPrevious() {
    this.scrollContainer.nativeElement.scrollTo({
      left: this.scrollContainer.nativeElement.scrollLeft - 250,
      behavior: 'smooth',
    });
  }

  showNext() {
    this.scrollContainer.nativeElement.scrollTo({
      left: this.scrollContainer.nativeElement.scrollLeft + 250,
      behavior: 'smooth',
    });
  }
}

export enum SortBy {
  TAG_LIST = 'TAG_LIST',
}

export enum Day {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  YESTERDAY = 'yesterday',
}

export enum CameFrom {
  BATCH = 'batch',
  DAY = 'day',
}

export enum ScheduleKey {
  SLUG = 'slug',
  DISPLAY_ORDER = 'display_order',
}
