import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../../services/global/global.service';
import { StudyFunctionalityService } from '../../services/study-functionality/study-functionality.service';
import {
  AllPurchasedBatches,
  BatchDetailModel,
  RecentWatchedVideosModel,
} from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics/firebase-analytics.service';
import { StudyService } from '../../services/study/study.service';
import { CONTINUE_LEARNING, STREAK_CLICK } from 'src/app/core/analytics-events';
import { EntryComponentName } from '../../config/entry-point.config';
import { StorageService } from '../../services/storage/storage.service';
import { SortBy } from 'src/app/pages/batch/study/components/schedule-class/schedule-class.component';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { BatchService } from '../../services/batch/batch.service';
import { PAGINATION_LIMIT } from 'src/app/constants/app.constant';
import { ShowErrorService } from '../../services/showError/show-error.service';
import { PartPaymentService } from '../../services/part-payment/part-payment.service';
import { UpcomingInstalmentData } from '../../services/batch/batch.modal';
import {
  ApplicationTypes,
  IFrameActions,
  IFrameMessageProps,
  StreakInfoType,
} from '../../services/streak/streak.service';
import { GlobalObjectService } from '../../services/global-object/global-object.service';
import { StreakInstructionComponent } from './streak-instruction/streak-instruction.component';
import { CohortService } from '../../services/cohort/cohort.service';

@Component({
  selector: 'app-streak',
  templateUrl: './streak.component.html',
  styleUrls: ['./streak.component.scss'],
})
export class StreakComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('streakIframe', { static: false }) streakIframe!: ElementRef;
  @Output() closeStreakEvent = new EventEmitter<void>();
  isLoaded: boolean = true;
  url = environment.LEARN_2_EARN_LINK;
  selectedBatch!: AllPurchasedBatches;
  videoList: Array<RecentWatchedVideosModel> = [];
  batchlistQuery: { page: number; mode: number; sort: string };
  batchlistPaginate: boolean = false;
  processing: boolean = false;
  batchList: Array<AllPurchasedBatches> = [];
  pickFromCache: boolean = false;
  batchDetailsSub!: Subscription;
  batchDetail!: BatchDetailModel;
  private _partPaymentService: PartPaymentService;
  upcomingInstalment = new UpcomingInstalmentData({});
  iframeHeight: string = '';

  constructor(
    public dialogRef: MatDialogRef<StreakComponent>,
    private router: Router,
    private globalService: GlobalService,
    private studyFunctionalityService: StudyFunctionalityService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private studyService: StudyService,
    private globalObjectService: GlobalObjectService,
    private storageService: StorageService,
    private localStorageService: StorageService,
    private batchService: BatchService,
    private errorService: ShowErrorService,
    public dialog: MatDialog,
    private cohortService: CohortService,
    @Inject(MAT_DIALOG_DATA) public data: StreakInfoType
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.onBackdropClick();
    });
    this.batchlistQuery = {
      page: 1,
      mode: 1,
      sort: SortBy.TAG_LIST,
    };
    this.getBatchList();
  }

  ngOnInit(): void {
    this.globalObjectService.windowObj.addEventListener(
      'message',
      this.eventHandler
    );
    let redirectUrl = '';
    if (!this.data) {
      this.dialogRef.close();
      const dialogRef = this.dialog.open(StreakInstructionComponent, {
        panelClass: 'streak-modal',
        data: {},
        disableClose: true,
      });
    } else {
      // TODO: Need to figure out how to give iframes dynamic height and remove these hardcoded heights
      if (this.data?.firstTimeUser === true && this.data?.streakCount === 0) {
        redirectUrl = `/introduction-streak?type=firstTimeUser&todaysWatchTime=${this.data?.todaysWatchTime}`;
        this.iframeHeight = '400px';
      } else if (
        this.data?.firstTimeUser === false &&
        this.data?.streakCount === 0
      ) {
        redirectUrl = `/introduction-streak?type=restartStreak&todaysWatchTime=${this.data?.todaysWatchTime}`;
        this.iframeHeight = '400px';
      } else if (
        this.data?.todaysWatchTime >= 10 &&
        this.data?.showGraceModal
      ) {
        redirectUrl = `/streakComplete?streakCount=${this.data?.streakCount}`;
        this.iframeHeight = '480px';
      } else if (this.data?.showGraceModal) {
        redirectUrl = `/introduction-streak?type=streakSaved&todaysWatchTime=${this.data?.todaysWatchTime}`;
        this.iframeHeight = '400px';
      } else {
        redirectUrl = `/daystreak?streakCount=${this.data?.streakCount}&todaysWatchTime=${this.data?.todaysWatchTime}&graceTimeLeft=${this.data?.graceTimeLeft}`;
        if (this?.data?.isOnGrace && this.data?.todaysWatchTime < 10) {
          this.iframeHeight = '580px';
        } else {
          if (this.data?.todaysWatchTime >= 10) {
            this.iframeHeight = '470px';
          } else {
            this.iframeHeight = '530px';
          }
        }
      }
    }

    this.redirectUser(redirectUrl);
  }

  showStreakInstructionModal() {
    const dialogRef = this.dialog.open(StreakComponent, {
      panelClass: 'streak-modal',
      data: '',
      disableClose: true,
    });
  }

  onBackdropClick(): void {
    this.closeStreakModal();
  }

  ngAfterViewInit(): void {}

  redirectUser(redirectUrl: string) {
    this.url =
      this.url +
      `authenticate?token=${
        this.globalService.accessToken
      }&random_id=${this.globalService.getRandomId()}&redirectUrl=${encodeURIComponent(
        redirectUrl
      )}`;
  }

  ngOnDestroy() {
    this.globalObjectService.windowObj.removeEventListener(
      'message',
      this.eventHandler
    );
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.isLoaded = false;
  }

  async handleStartStreak() {
    try {
      this.setClickType();
      this.dialogRef.close();
      const recentVideos = await firstValueFrom(
        this.studyFunctionalityService.getRecentVideoList()
      );
      this.videoList = recentVideos?.length ? recentVideos : [];
      await this.getSelectedBatch();

      if (this.videoList.length > 0) {
        this.goToVideo(this.videoList[0]);
        return;
      }

      const batchId = this.selectedBatch?.batch?._id;
      if (batchId) {
        this.router.navigate([`/batches`], {
          queryParams: {
            batchChildUrl: `/batches-new/batches/${batchId}/batch-overview#All Classes_2`,
          },
        });

        return;
      }

      this.router.navigate([`/batches`]);
    } catch (error) {
      console.error('Error in handleStartStreak:', error);
    }
  }

  async getBatchList() {
    if (this.batchlistQuery.page === 1) {
      this.batchlistPaginate = true;
      this.processing = true;
      this.batchList.splice(0);
    }

    try {
      const res = await lastValueFrom(
        this.batchService.getAllPurchasedBatches(this.batchlistQuery)
      );
      if (res) {
        this.batchlistPaginate = res.length === PAGINATION_LIMIT;
        this.batchList = [
          ...this.batchList,
          ...res.map((item: any) => new AllPurchasedBatches(item)),
        ];
      }
    } catch (error: any) {
      if (error?.error?.status === 503 || error?.error?.status === 504) {
        this.pickFromCache = true;
      } else {
        this.errorService.showError(error);
      }
    } finally {
      this.processing = false;
      // if (this.pickFromCache) {
      //   this.batchList = this.storageService.getScheduleClassData().batchList;
      // }
      if (this.batchList.length > 0) {
        await this.getSelectedBatch();
      }
    }
  }

  async getSelectedBatch() {
    const scheduleBatch =
      this.localStorageService.getScheduleClassBatch() ||
      new AllPurchasedBatches({});
    this.selectedBatch =
      this.batchList.find(
        (batch) => batch.batch._id === scheduleBatch.batch._id
      ) || this.batchList[0];
  }

  closeStreakModal = () => {
    const dataForEvents = {
      click_text: '',
      click_type: 'streak_widget_icon',
    };
    if (typeof window !== 'undefined') {
      if (this.data?.firstTimeUser && this.data?.streakCount === 0) {
        localStorage.setItem('streak_introduction_modal_viewed', 'true');
        dataForEvents.click_type = 'auto';
      } else if (
        this.data?.showGraceModal &&
        this.data?.todaysWatchTime >= 10
      ) {
        localStorage.setItem('streak_completion_modal_viewed', 'true');
        dataForEvents.click_type = 'auto';
        this.closeStreakEvent.emit();
      } else if (this.data?.showGraceModal) {
        localStorage.setItem('streak_grace_modal_viewed', 'true');
        dataForEvents.click_type = 'auto';
        this.closeStreakEvent.emit();
      }
    }
    this.logEvent(dataForEvents.click_text, dataForEvents.click_type);
    this.dialogRef.close();
  };

  setClickType = (isHereClicked?: boolean) => {
    const dataForEvents = {
      click_text: '',
      click_type: 'streak_widget_icon',
    };
    if (this.data?.firstTimeUser && this.data?.streakCount === 0) {
      dataForEvents.click_type = 'auto';
      dataForEvents.click_text = 'Start my streak';
      localStorage.setItem('streak_introduction_modal_viewed', 'true');
    } else if (
      this.data?.firstTimeUser === false &&
      this.data?.streakCount === 0
    ) {
      dataForEvents.click_text = 'Complete my streak';
    } else if (this.data?.streakCount > 0 && this.data?.todaysWatchTime < 10) {
      dataForEvents.click_text = 'Extent my streak';
      if (this.data?.showGraceModal) {
        dataForEvents.click_type = 'auto';
        localStorage.setItem('streak_grace_modal_viewed', 'true');
      }
    }

    if (isHereClicked) {
      dataForEvents.click_text = 'Here';
    }
    this.logEvent(dataForEvents.click_text, dataForEvents.click_type);
  };

  logEvent = (click_text: string, click_type: string) => {
    const dataForEvents = {
      cohort_id: this.cohortService.getCohortIdFromLocal(),
      batch_id: this.selectedBatch?.batch?._id,
      click_text,
      click_type,
    };
    this.firebaseAnalytics.logEvents(STREAK_CLICK, dataForEvents);
  };

  eventHandler = (event: MessageEvent<IFrameMessageProps>) => {
    if (
      event.data.action === IFrameActions.START_STREAK &&
      event.data.applicationType === ApplicationTypes.PW_STREAK
    ) {
      this.handleStartStreak();
    }
    if (
      event.data.action === IFrameActions.STREAK_INSTRUCTIONS &&
      event.data.applicationType === ApplicationTypes.PW_STREAK
    ) {
      this.setClickType(true);
      this.showStreakInstructionModal();
    }
    if (
      event.data.action === IFrameActions.SHARE_ICON_CLICK &&
      event.data.applicationType === ApplicationTypes.PW_STREAK
    ) {
      this.logEvent('Share icon', 'streak_widget_icon');
    }
  };

  goToVideo(event: RecentWatchedVideosModel) {
    this.firebaseAnalytics.logEvents(CONTINUE_LEARNING, { action: 'resume' });
    if (event?.batchDetail && event?.scheduleId) {
      this.studyService.goToVideo(
        event,
        {
          batchId: event.batchDetail.slug || event.batchDetail._id,
          scheduleId: event.scheduleId,
        },
        false,
        EntryComponentName.CONTINUE_LEARNING_VIDEOS
      );
    } else {
      this.globalService.showSnackBar('Batch details not found');
    }
  }
}
