import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { StreakComponent } from '../streak-component';

@Component({
  selector: 'app-streak-instruction',
  templateUrl: './streak-instruction.component.html',
  styleUrls: ['./streak-instruction.component.scss'],
})
export class StreakInstructionComponent implements OnInit {
  isLoaded: boolean = true;
  url = environment.LEARN_2_EARN_LINK;

  constructor(
    public dialogRef: MatDialogRef<StreakComponent>,
    private globalService: GlobalService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.redirectUser('/streak-instruction');
  }
  redirectUser(redirectUrl: string) {
    this.url =
      this.url +
      `authenticate?token=${
        this.globalService.accessToken
      }&random_id=${this.globalService.getRandomId()}&redirectUrl=${encodeURIComponent(
        redirectUrl
      )}`;
  }
  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.isLoaded = false;
  }

  closeStreakModal = () => {
    this.dialogRef.close();
  };
}
