<div>
  <div *ngIf="this.showStreakInfoData?.showStreak">
    <div *ngIf="!showStreakCompletionAnimation; else streakAnimation">
      <div
        class="streak-btn relative"
        (click)="onStreakClick(streakInfoData)"
        *ngIf="router.url.endsWith('/study') && !showStreakAnimation"
      >
        <div
          [ngStyle]="{
            'background-color': getStreakFillStyle(
              streakInfoData?.todaysWatchTime || 0
            ).radius,
            'background-image': getStreakFillStyle(
              streakInfoData?.todaysWatchTime || 0
            ).radius,
            'border-radius': '50%'
          }"
          class="streak-border"
        >
          <div
            class="streak-container"
            [ngStyle]="{
              'background-color': getStreakFillStyle(
                streakInfoData?.todaysWatchTime || 0
              ).backgroundColor,
              'background-image': getStreakFillStyle(
                streakInfoData?.todaysWatchTime || 0
              ).backgroundImage,
              'border-radius': '50%'
            }"
          >
            <img
              [src]="
                (streakInfoData?.todaysWatchTime || 0) >= 10
                  ? streakRedIcon
                  : streakFireImg
              "
              class="streak-img"
            />
          </div>
        </div>

        <div *ngIf="getGraceTimeAlert()">
          <ng-lottie
            [options]="streakRedTriangle"
            height="16px"
            width="16px"
            class="streakAlertTriangle"
          ></ng-lottie>
        </div>
        <div class="streak-count">{{ streakInfoData?.streakCount || 0 }}</div>
      </div>

      <div
        class="h-10 float-right parent-div relative overflow-hidden w-[64px] px-3 py-1 streak-btn"
        *ngIf="router.url.endsWith('/study') && showStreakAnimation"
      >
        <div
          class="w-[122px] absolute right-2 opacity-0 z-10 top-div flex items-center flex-row gap-[2px]"
        >
          <img [src]="streakWoodFireIcon" style="vertical-align: middle" />
          <span class="text-[#012D45] text-base font-semibold">
            Streak Saved
          </span>
        </div>
        <div class="w-fit absolute flex items-center flex-row gap-1 bottom-div">
          <div
            [ngStyle]="{
              'background-color': getStreakFillStyle(
                streakInfoData?.todaysWatchTime || 0
              ).radius,
              'background-image': getStreakFillStyle(
                streakInfoData?.todaysWatchTime || 0
              ).radius,
              'border-radius': '50%'
            }"
            class="streak-border"
          >
            <div
              class="streak-container"
              [ngStyle]="{
                'background-color': getStreakFillStyle(
                  streakInfoData?.todaysWatchTime || 0
                ).backgroundColor,
                'background-image': getStreakFillStyle(
                  streakInfoData?.todaysWatchTime || 0
                ).backgroundImage,
                'border-radius': '50%'
              }"
            >
              <img
                [src]="
                  (streakInfoData?.todaysWatchTime || 0) >= 10
                    ? streakRedIcon
                    : streakFireImg
                "
                class="streak-img"
              />
            </div>
          </div>
          <span class="text-[#012D45] text-base font-semibold">{{
            streakInfoData.streakCount
          }}</span>
        </div>
      </div>
    </div>
    <ng-template #streakAnimation>
      <div *ngIf="isNumberChanged; else notChangedTemplate">
        <div
          [ngStyle]="{
            borderRadius: '50px',
            border: '1px solid var(--Auxillary-Blue-Blue-400, #2b91c9)',
            background: 'var(--Greys-White, #fff)'
          }"
          class="h-10 mr-2 float-right flex flex-row items-center parent-div relative overflow-hidden px-2 py-1"
        >
          <div
            [ngStyle]="{
              width: streakInfoData.streakCount.toString().length * 70 + 'px'
            }"
            class="opacity-0 z-10 top-div flex items-center flex-row gap-[2px] justify-start"
          >
            <div *ngIf="isFireLottie">
              <ng-lottie
                [options]="fireLottie"
                height="20px"
                width="20px"
              ></ng-lottie>
            </div>

            <span class="text-[#012D45] text-base font-semibold"
              >{{ streakInfoData?.streakCount || 0 }} Day Streak</span
            >
          </div>

          <div
            class="w-[66px] absolute flex items-center flex-row gap-[2px] bottom-div"
          >
            <div
              [ngStyle]="{
                backgroundImage:
                  'conic-gradient(' +
                  (percentage === 100 ? '#1B7938' : '#5A4BDA') +
                  ' ' +
                  percentage +
                  '%, transparent ' +
                  percentage +
                  '%)',
                backgroundSize: 'cover',
                transition: 'background-image 0.5s ease-in-out'
              }"
              class="!h-7 !w-7 rounded-full flex justify-center items-center"
            >
              <div
                class="!h-[26px] !w-[26px] rounded-full flex justify-center items-center bg-white"
              >
                <div
                  [ngStyle]="{
                    background: percentage === 100 ? '#FFF6E5' : '#E4E7EA'
                  }"
                  class="!w-[23px] relative flex justify-center items-center !h-[23px] rounded-full"
                >
                  <div [ngClass]="{ grayscale: percentage !== 100 }">
                    <img [src]="streakRedFireImg" class="streakRedFire" />
                  </div>

                  <div
                    *ngIf="percentage !== 100"
                    [ngStyle]="{
                      backgroundImage:
                        'conic-gradient(#FFF6E5 ' +
                        percentage +
                        '%, transparent ' +
                        percentage +
                        '%)',
                      backgroundSize: 'cover',
                      transition: 'background-image 0.5s ease-in-out',
                      opacity: '0.60'
                    }"
                    class="absolute left-0 top-0 w-[23px] h-[23px] rounded-full"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #notChangedTemplate>
        <div
          [ngStyle]="{
            borderRadius: '50px',
            border: '1px solid var(--Auxillary-Blue-Blue-400, #2b91c9)',
            background: 'var(--Greys-White, #fff)'
          }"
          class="h-10 mr-2 relative float-right w-full px-2 py-1 flex items-center flex-row gap-[2px]"
        >
          <div
            [ngStyle]="{
              backgroundImage:
                'conic-gradient(' +
                (percentage === 100 ? '#1B7938' : '#5A4BDA') +
                ' ' +
                percentage +
                '%, transparent ' +
                percentage +
                '%)',
              backgroundSize: 'cover',
              transition: 'background-image 0.5s ease-in-out'
            }"
            class="!h-7 !w-7 relative rounded-full flex justify-center items-center"
          >
            <div
              class="!h-[26px] !w-[26px] rounded-full flex justify-center items-center bg-white"
            >
              <div
                [ngStyle]="{
                  background: percentage === 100 ? '#FFF6E5' : '#E4E7EA'
                }"
                class="!w-[23px] relative flex justify-center items-center !h-[23px] rounded-full"
              >
                <div [ngClass]="{ grayscale: percentage !== 100 }">
                  <ng-lottie
                    [options]="confettiLottie"
                    height="30px"
                  ></ng-lottie>
                </div>
                <img [src]="streakRedFireImg" class="streakRedFire" />
                <div
                  *ngIf="percentage !== 100"
                  [ngStyle]="{
                    backgroundImage:
                      'conic-gradient(#FFF6E5 ' +
                      percentage +
                      '%, transparent ' +
                      percentage +
                      '%)',
                    backgroundSize: 'cover',
                    transition: 'background-image 0.5s ease-in-out',
                    opacity: '0.60'
                  }"
                  class="absolute left-0 top-0 w-[23px] h-[23px] rounded-full"
                ></div>
              </div>
            </div>
          </div>

          <div
            [ngStyle]="{
              width: streakInfoData.streakCount.toString().length * 15 + 'px'
            }"
            class="h-6 relative overflow-hidden flex justify-center items-center"
          >
            <div *ngIf="percentage === 100; else scoreDisplay">
              <div
                class="score-change absolute top-0 left-0 flex flex-col ml-1"
              >
                <span class="text-[#012D45] h-6 text-base font-semibold">{{
                  streakInfoData.streakCount - 1
                }}</span>
                <span class="text-[#012D45] h-6 text-base font-semibold">{{
                  streakInfoData?.streakCount
                }}</span>
              </div>
            </div>
            <ng-template #scoreDisplay>
              <span class="text-[#012D45] text-base font-semibold">{{
                streakInfoData?.streakCount
              }}</span>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
