import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInSeconds } from 'date-fns';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { BANNER_IMG } from 'src/app/core/assets.location';
import { BatchService } from '../batch/batch.service';
import { ConversationService } from '../conversation/conversation.service';
import { GlobalService } from '../global/global.service';
import { LoaderService } from '../loader/loader.service';
import { ShowErrorService } from '../showError/show-error.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { PPApiService } from '../../api/api.service';
import { handleError } from '../error-handler/error-handler.service';
import { GlobalObjectService } from '../global-object/global-object.service';
import { StorageService } from '../storage/storage.service';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { MatDialog } from '@angular/material/dialog';
import { FilterModalComponent } from 'src/app/pages/batch/study/components/study-section-card/filter-modal/filter-modal.component';
import { CohortService } from '../cohort/cohort.service';
import { environment } from 'src/environments/environment';
import { ExpiryCountObject } from '../batch/batch.modal';

@Injectable({
  providedIn: 'root',
})
export class StudyService {
  batchList: Array<BatchDetailModel> = [];
  cohortId: string = '';
  constructor(
    private batchService: BatchService,
    private loaderService: LoaderService,
    private globalService: GlobalService,
    private conversationService: ConversationService,
    private showErrorService: ShowErrorService,
    private router: Router,
    private apiService: PPApiService,

    private globalObjectService: GlobalObjectService,
    private storageService: StorageService,
    private dialog: MatDialog,
    private cohortService: CohortService
  ) {}

  getVideoImage(data: any) {
    if (
      data &&
      data.videoDetails &&
      data.videoDetails.image &&
      data.videoDetails?.image.length > 0
    ) {
      return data?.videoDetails?.image;
    } else {
      return BANNER_IMG;
    }
  }

  getVideoName(name: any, len: any) {
    if (name && name.length > len) {
      return name.substr(0, len) + '...';
    } else {
      return name;
    }
  }

  getVideoSubjectName(name: string, len: number) {
    if (name && name.length > len) {
      return name.substr(0, len) + '...';
    } else {
      return name;
    }
  }

  getVideoDuration(data: any) {
    if (data && data.videoDetails) {
      return data?.videoDetails?.duration;
    } else {
      return 'Duration';
    }
  }

  changeTimeIntoSec(lectureData: any) {
    let totalTime = 0;
    if (
      lectureData &&
      lectureData.videoDetails &&
      lectureData.videoDetails.duration
    ) {
      const time = lectureData.videoDetails.duration;
      let duration = time.split(':');

      if (duration.length === 3) {
        totalTime =
          parseInt(duration[0]) * 3600 +
          parseInt(duration[1]) * 60 +
          parseInt(duration[2]);
      } else {
        totalTime = parseInt(duration[0]) * 60 + parseInt(duration[1]);
      }
    }
    return totalTime;
  }

  // ---------- change time into second end ----------

  // --------- play video start ------------------

  formatTime(timeInSeconds: any) {
    const hours: number = Math.floor(timeInSeconds / 3600);
    const minutes: number = Math.floor((timeInSeconds % 3600) / 60);

    if (hours === 0) return ('00' + minutes).slice(-2) + ' minutes ';

    return (
      ('00' + hours).slice(-2) +
      ' hours ' +
      ('00' + minutes).slice(-2) +
      ' minutes '
    );
  }

  async goToVideo(
    event: any,
    query: any,
    isUnderMaintenance?: boolean,
    entryPoint?: string
  ) {
    isUnderMaintenance = isUnderMaintenance || false;
    const loaderMessage = 'Please wait...';
    const timeRemainingStart = differenceInSeconds(
      new Date(event.startTime),
      new Date()
    );
    let url = '';
    if (event?.urlType === 'premiumWebrtc') {
      const newBaseUrl = `${window.location.origin}/classroom/pw-live-class/${query.scheduleId}`;
      window.location.href = newBaseUrl;
      this.loaderService.unloadData('Loading...');
      return;
    }

    if (timeRemainingStart / 86400 > 2) {
      this.loaderService.unloadData(loaderMessage);
      const message =
        'Class Will be live after ' +
        Math.round(timeRemainingStart / 86400) +
        'days';
      await this.globalService.showSnackBar(message);
      return;
    } else if (timeRemainingStart / 86400 > 1) {
      this.loaderService.unloadData(loaderMessage);
      const message = 'Class Will be live tomorrow';
      await this.globalService.showSnackBar(message);
      return;
    } else if (timeRemainingStart / 86400 > 0) {
      this.loaderService.unloadData(loaderMessage);
      const message =
        'Class Will be live after ' + this.formatTime(timeRemainingStart);
      await this.globalService.showSnackBar(message);
      return;
    }

    // zoom integration
    if (event?.urlType === 'zoomWebinar') {
      const query = {
        type: 'student',
        webinar_id: event._id,
        mentor_id: event?.zoomHostId || '',
        redirection_url: this.globalObjectService.window?.location?.href || '',
      };

      this.batchService.getZoomMeetingLinkData(query);

      return;
    }

    let params: any;
    const timeRemainingEnd = differenceInSeconds(
      new Date(event.endTime),
      new Date()
    );

    if (timeRemainingEnd > 0) {
      event.isLive = true;
      const loaderMessage = 'Please wait...';

      try {
        this.loaderService.unloadData(loaderMessage);
        const response = await lastValueFrom(
          this.conversationService.joinClass(
            query.batchId,
            event.subjectId._id,
            query.scheduleId,
            { conversationId: event.conversationId }
          )
        );

        if (response && response['data']) {
          // @ts-ignore
          params['joiningId'] = response['data']._id;
          // @ts-ignore
          this.globalService.setJoiningId(response['data']._id);
        }
        if (event.conversationId) {
          this.globalService.showSnackBar('Joined Chat');
        }
      } catch (e) {
        console.log(e);
        this.loaderService.unloadData(loaderMessage);
      }
    } else if (event.restrictedSchedule && event.restrictedTime > 0) {
      try {
        const response: any = await lastValueFrom(
          await this.batchService.checkWatchLimit(
            query.batchId,
            event.batchSubjectId,
            query.scheduleId,
            {}
          )
        );

        const userWatchTime = response['data'];
        this.loaderService.unloadData(loaderMessage);

        if (userWatchTime.watchTime > event.restrictedTime) {
          return;
        } else {
          this.globalService.showSnackBar(
            `You have consumed ${new Date(userWatchTime.watchTime * 1000)
              .toISOString()
              .substr(11, 8)} from ${Math.round(event.restrictedTime / 60)}min.`
          );
        }
      } catch (e) {
        this.loaderService.unloadData(loaderMessage);
        await this.showErrorService.showError(e);
      }
    }

    this.globalService.setVideoDetails(event);
    this.globalService.setVideoId(
      event?.videoDetails?.id || event?.videoDetails_id || 'NA'
    );
    this.batchService.setVideoBackUrl(this.router.url);
    this.cohortId = this.cohortService.getCohortIdFromLocal();
    const _entryPoint = `${entryPoint}_${this.cohortId}`;

    await this.getBatchDetails(query.batchId);
    url = `batches/${query.batchId}/subjects/${event.batchSubjectId}/subject-details/all/batch-video-player?scheduleId=${query.scheduleId}&dRoom=${event.dRoomId}&batchSubjectId=${event.batchSubjectId}&type=${event?.urlType}`;

    if (isUnderMaintenance) url += `&isUnderMaintenance=${isUnderMaintenance}`;
    if (entryPoint) url += `&entryPoint=${_entryPoint}`;
    if (event?.urlType === 'premiumWebrtc' && typeof window !== 'undefined') {
      const _finalURL = `/classroom/pw-live-class/${query?.scheduleId}`;
      window.location.replace(_finalURL);
    } else {
      this.router.navigateByUrl(url);
    }

    this.loaderService.unloadData('Loading...');
  }

  async getBatchDetails(batchId: string) {
    try {
      const res = await lastValueFrom(
        this.batchService.getBatchDetails(batchId)
      );
      if (res) {
        this.batchService.setBatchData(res);
      }
    } catch (error) {
      this.showErrorService.showError(error);
    }
  }

  getWidgets(query: any) {
    query = query || {};
    const options: PPApiOptions = {
      apiPath: `v3/widget`,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }
  fetchReels(query: any) {
    const options: PPApiOptions = {
      apiPath: 'v3/reels/users/feed-posts/study',
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  //Fetch Count of Expired & Expiry Soon Batches
  getExpiredCount() {
    const options: PPApiOptions = {
      apiPath: 'v3/batch-service/batches/expired-count',
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }
  snoozeExpiryBanner(data?: any) {
    const options: PPApiOptions = {
      apiPath: 'batch-service/v3/batch-students/expired-banner/snooze ',
    };

    return this.apiService.put(data, options).pipe(catchError(handleError));
  }

  async getStudyWidgets(query: any) {
    try {
      const res: any = await lastValueFrom(this.getWidgets(query));

      if (res) {
        return new WidgetObject(res);
      } else {
        return new WidgetObject({});
      }
    } catch (e) {
      return new WidgetObject({});
    }
  }

  redirectTo(url: string) {
    if (url.length > 0) {
      if (url.includes('my-doubts')) {
        this.goToMyDoubts();
        return;
      }
      if (url.includes('marketplace') || url.includes('store')) {
        const token = this.globalService.getAccessToken().value;
        const randomId = this.globalService.getRandomId();
        const redirectUrl = url + `&token=${token}&random_id=${randomId}`;
        this.globalObjectService.window?.open(redirectUrl, '_self');
      } else if (url?.includes('rank-predictor')) {
        const redirectUrl = environment.pwRedirectUrl + url;
        this.globalObjectService.window?.open(redirectUrl, '_self');
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }

  goToMyDoubts() {
    const localData = this.storageService.getMyDoubtSelectedBatch();
    if (localData && (localData.slug || localData.id)) {
      const batchId = localData.slug || localData.id;
      this.globalService.cameFromUrl$.next('study');
      this.router.navigate([`batches/${batchId}/batch-overview/batch-doubts`]);
    } else {
      this.getBatchList();
    }
  }

  async getBatchList() {
    const message = 'plase wait ...';
    const query = {
      mode: 1,
      isDoubtEnabled: true,
      sort: 'TAG_LIST',
      filter: true,
    };
    await this.loaderService.loadData(message);
    this.batchList.splice(0);
    try {
      const res = await lastValueFrom(this.batchService.getAllBatches(query));
      let list: Array<BatchDetailModel> = [];
      if (res) {
        list = res.map((item: any) => new BatchDetailModel(item));
      }
      this.batchList = [...this.batchList, ...list];
    } catch (error) {
    } finally {
      await this.loaderService.unloadData(message);
      this.openFilterModal();
    }
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(FilterModalComponent, {
      panelClass: 'study-filter-modal',
      data: {
        batchList: this.batchList,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.router.navigate([
          `batches/${res.slug}/batch-overview/batch-doubts`,
        ]);
      }
    });
  }

  async getReels(query: any) {
    try {
      const res = await lastValueFrom(this.fetchReels(query));
      if (res) {
        return res.map((reel: any) => new Reel(reel));
      }
    } catch (e) {
      return [];
    }
  }
}

export enum WidgetType {
  ContinueLearning = 'ContinueLearning',
  ScheduledClass = 'ScheduledClass',
  CarouselBanner = 'CarouselBanner',
  BoxTile = 'BoxTile',
  RectangularTile = 'RectangularTile',
  GoldenContainer = 'GoldenContainer',
  EndToEndContainer = 'EndToEndContainer',
  Share = 'Share',
  RecommendationCard = 'RecommendationCard',
  Dashboard = 'Dashboard',
  Reel = 'Reel',
  QuickAccess = 'QuickAccess',
  Explore = 'Explore',
  AIGrader = 'AIGrader',
  NewLibrary = 'NewLibrary',
}

export enum Platform {
  WEB = 'web',
  MOBILE_WEB = 'mobileWeb',
  ANDROID = 'android',
  MWEB = 'mweb',
}
export enum Placeholder {
  STUDY_PAGE = 'Study page',
  ALL_CLASSES = 'All classes',
}
export enum Category {
  STUDY = 'StudyPage',
  BATCH = 'BatchPage',
  ALL_CLASSES = 'AllClasses',
}

export enum UserSegment {
  NO_BATCH = 'NoBatch',
  FREE = 'Free',
  PAID = 'Paid',
}

export class CTA {
  viewAll: boolean;
  offlineToggle: boolean;
  onlineToggle: boolean;
  viewAllRedirectTo: string;

  constructor(data: any) {
    data = data || {};
    this.viewAll = data.viewAll || false;
    this.offlineToggle = data.offlineToggle || false;
    this.onlineToggle = data.onlineToggle || false;
    this.viewAllRedirectTo = data.viewAllRedirectTo || '';
  }
}

export class Config {
  cta: CTA;
  bgColor: string;
  carousalTimer?: number;
  dashboard: any[];
  isNew: boolean;
  testModeId?: string;
  aiSubjectiveFilter?: string;
  constructor(data: any) {
    data = data || {};
    this.cta = new CTA(data.cta);
    this.bgColor = data.bgColor || '#3D405B';
    this.carousalTimer = data.carousalTimer || 1;
    this.dashboard = data.dashboard || [];
    this.isNew = data.isNew || false;
    this.testModeId = data.testModeId || '';
    this.aiSubjectiveFilter = data.aiSubjectiveFilter || '';
  }
}

export class Image {
  baseUrl: string;
  _id: string;
  key: string;

  constructor(data: any) {
    data = data || {};
    this.baseUrl = data.baseUrl || '';
    this._id = data._id || '';
    this.key = data.key || '';
  }
}

export class Option2 {
  _id: string;
  image: Image;
  displayOrder: number;
  redirectTo: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.image = new Image(data.image) || new Image({});
    this.displayOrder = data.displayOrder || 0;
    this.redirectTo = data.redirectTo || '';
  }
}

export class Option {
  _id: string;
  image: Image;
  displayOrder: number;
  options: Option2[];
  title: string;
  subTitle: string;
  redirectTo: string;
  videoDetails?: any;
  videoType?: string;
  videoUrl?: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.image = new Image(data.image) || new Image({});
    this.displayOrder = data.displayOrder || 0;
    this.title = data.title || '';
    this.subTitle = data.subTitle || '';
    this.redirectTo = data.redirectTo || '';
    this.videoDetails = data?.videoDetails || {};
    this.videoType = data.videoType || '';
    this.videoUrl = data.videoUrl || '';

    if (data.options) {
      this.options = data.options.map((item: any) => new Option2(item));
    } else {
      this.options = [];
    }
  }
}

export class Component {
  image: Image;
  _id: string;
  type: string;
  title: string;
  subTitle: string;
  redirectUrl: string;
  config: any;

  constructor(data: any) {
    this.image = new Image(data.image || {});
    this._id = data._id || '';
    this.type = data.type || '';
    this.title = data.title || '';
    this.subTitle = data.subTitle || '';
    this.redirectUrl = data.redirectUrl || '';
    this.config = data.config || {};
  }
}

export class Widget {
  config: Config;
  status: string;
  _id: string;
  type: string;
  title: string;
  options: Option[];
  components: Component[];
  displayOrder: number;
  subTitle: string;

  constructor(data: any) {
    this.config = new Config(data.config) || new Config({});
    this.status = data.status || '';
    this._id = data._id || '';
    this.type = data.type || '';
    this.title = data.title || '';
    this.displayOrder = data.displayOrder || 0;
    this.subTitle = data.subTitle || '';

    if (data.options) {
      this.options = data.options.map((item: any) => new Option(item));
    } else {
      this.options = [];
    }
    if (data.components) {
      this.components = data.components.map((item: any) => new Component(item));
    } else {
      this.components = [];
    }
  }
}

export class WidgetObject {
  _id: string;
  batchUserSegment: string;
  platform: string;
  category: string;
  cohortId: string;
  widgets: Widget[];
  createdAt: string;
  updatedAt: string;

  constructor(data: any) {
    this._id = data._id || '';
    this.batchUserSegment = data.batchUserSegment || '';
    this.platform = data.platform || '';
    this.category = data.category || '';
    this.cohortId = data.cohortId || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';

    if (data.widgets) {
      this.widgets = data.widgets
        .map((item: any) => new Widget(item))
        .sort((a: Widget, b: Widget) => a.displayOrder > b.displayOrder);
    } else {
      this.widgets = [];
    }
  }
}

export class Reel {
  thumbnail: string;
  description: string;
  _id: string;
  views: number;
  viewsString: string;

  constructor(data: any) {
    data = data || {};
    this.thumbnail = data.thumbnail || '';
    this.description = data.description || '';
    this._id = data._id || '';
    this.views = data.views || '';
    this.viewsString = data.viewsString || '';
  }
}
