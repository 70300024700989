import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './common/services/global/global.service';
import {
  BatchRevampService,
  UnleashApiPayloadType,
} from './common/services/batch-revamp/batch-revamp.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard
  implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad
{
  constructor(
    private globalService: GlobalService,
    private router: Router,
    private batchRevampService: BatchRevampService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): // @ts-ignore
  | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.globalService.isAuth()) {
      return true;
    } else {
      const redirectUrl = window.location.href.includes('/study/')
        ? window.location.href.split('/study/')[1]?.replace('?', '&')
        : window.location.pathname + window.location.search;

      if (!window.location.origin.includes('localhost')) {
        window.location.assign(
          `${window.location.origin}?redirectUrl=${redirectUrl}`
        );
      } else {
        this.router.navigate(['auth'], {
          queryParams: {
            url: redirectUrl,
          },
        });
      }
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
}
