<div class="relative">
  <mat-icon (click)="closeStreakModal()" class="cursor-pointer close-icon"
    >close</mat-icon
  >

  <article [hidden]="isLoaded">
    <iframe
      #streakIframe
      class="responsive-iframe"
      id="streakIframe"
      (load)="checkLoaded($event, streakIframe)"
      [src]="url | safe : 'resourceUrl'"
      allow="accelerometer; autoplay;fullscreen; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
  </article>
</div>
