import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../services/global/global.service';
import { XP_ICON } from 'src/app/core/assets.location';
import { XP_SHINE_ICON, CROSS_ICON } from 'src/app/core/assets.location';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ApplicationTypes,
  IFrameActions,
  IFrameMessageProps,
  Learn2EarnService,
} from '../../services/learn-2-earn/learn-2-earn.service';
import { Subscription, debounceTime, filter, lastValueFrom, skip } from 'rxjs';
import { StorageService } from '../../services/storage/storage.service';
import { GlobalObjectService } from '../../services/global-object/global-object.service';
import { Learn2EarnConfigModel } from '../learn-2-earn/learn-2-earn.model';
import { CohortService } from '../../services/cohort/cohort.service';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics/firebase-analytics.service';

@Component({
  selector: 'xp-header-button',
  templateUrl: './xp-header-button.component.html',
  styleUrls: ['./xp-header-button.component.scss'],
})
export class XPHeaderButtonComponent implements OnInit {
  @Input('isFromVideo') isFromVideo: any;
  @Input('isFromCommonHeader') isFromCommonHeader: any;
  showXPButton: boolean = false;
  xpIcon = XP_ICON;
  xpShineIcon = XP_SHINE_ICON;
  crossIcon = CROSS_ICON;
  showAnimation: boolean = false;
  showToolTip: boolean = false;
  disableToolTipCheck: boolean = false;
  showCurrentXp: any;
  earnedXp: number = 0;
  learn2EarnConfig: Learn2EarnConfigModel;
  routerEventsSubscription: Subscription;
  cohortEventsSubscription: Subscription;
  learnToEarnSidebarSub: Subscription;
  cohortId: string;
  @ViewChild('xpHeaderButton') xpHeaderButtonRef: ElementRef;
  @ViewChild('xpGainImage') xpGainImageRef: ElementRef;
  @HostBinding('style.--target-top')
  private targetTop: string = '10%';
  @HostBinding('style.--target-left')
  private targetLeft: string = '90%';
  constructor(
    public dialog: MatDialog,
    private globalService: GlobalService,
    private location: Location,
    public router: Router,
    public learn2EarnService: Learn2EarnService,
    public storageService: StorageService,
    private globalObjectService: GlobalObjectService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private cohortService: CohortService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private cdr: ChangeDetectorRef
  ) {}

  handleCheckForToolTip(): void {
    const alreadySeen = localStorage.getItem('l2eToolTipSeen');
    if (
      this.showCurrentXp?.toolTipStatus === 'UNSEEN' ||
      this.showCurrentXp?.toolTipStatus === 'NONE'
    ) {
      if (
        this.showCurrentXp.weeklyTotalXP >= this.showCurrentXp.minimumXP &&
        alreadySeen !== this.learn2EarnConfig.leaderboardUpdateNextDate
      ) {
        this.showToolTip = true;
      }
    }
  }

  dismissToolTip(type: string): void {
    this.showToolTip = false;
    const timeSeen = this.learn2EarnConfig.leaderboardUpdateNextDate;
    localStorage.setItem('l2eToolTipSeen', timeSeen);
    if (type === 'openSidebar') {
      this.globalService.openLearnToEarnSideBar(true);
    }
  }

  ngOnInit(): void {
    this.routerEventsSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (
          this.isFromCommonHeader &&
          this.learn2EarnConfig?.isLearnToEarnActive
        ) {
          if (
            this.router.url.includes('/batches') ||
            this.router.url.includes('batches/study')
          ) {
            this.showXPButton = true;
            this.cdr.detectChanges();
          } else {
            this.showXPButton = false;
          }
        }
      });

    this.cohortEventsSubscription = this.cohortService.cohortConfig$
      .pipe(debounceTime(300))
      .subscribe((data: any) => {
        if (
          this.router.url.includes('khazana-topics') ||
          this.router.url.includes('batch-overview')
        ) {
          this.checkShouldShowXPButton(false, true);
          return;
        }
        if (data?._id && (!this.cohortId || this.cohortId !== data?._id)) {
          if (!this.router.url.includes('new-test-result')) {
            this.checkShouldShowXPButton(true, true);
          } else {
            this.checkShouldShowXPButton(false, true);
          }
          this.cohortId = data?._id;
        }
      });
    this.globalObjectService.windowObj.addEventListener(
      'message',
      this.eventHandler
    );
    this.learnToEarnSidebarSub = this.globalService
      .isLearnToEarnSideBarOpen()
      .pipe(skip(1), debounceTime(300))
      .subscribe(
        (res) => {
          if (res) {
            // Here you can add side bar open actions
          } else {
            this.getWeeklyXp(true);
          }
        },
        () => {
          console.log('Unable to get user menu value');
        }
      );
  }

  ngOnDestroy() {
    this.globalObjectService.windowObj.removeEventListener(
      'message',
      this.eventHandler
    );
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.cohortEventsSubscription) {
      this.cohortEventsSubscription?.unsubscribe();
    }
    if (this.learnToEarnSidebarSub) {
      this.learnToEarnSidebarSub.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    const targetDiv = this.xpHeaderButtonRef?.nativeElement;
    if (!targetDiv) {
      return;
    }
    const { top: initialTop, left: initialLeft } =
      targetDiv.getBoundingClientRect();
    const percentLeft = (initialLeft / window.innerWidth) * 105;
    const percentTop = (initialTop / window.innerHeight) * 98;
    this.targetTop = `${percentTop}%`;
    this.targetLeft = `${percentLeft}%`;
  }

  async getWeeklyXp(refetchXP: boolean = false) {
    try {
      const videoPlayer = localStorage.getItem('videoLearn2EarnData');
      const cohortId =
        this.storageService.getValue('COHORT_ID', 'string') || '';
      if (videoPlayer) {
        const localXp = JSON.parse(
          localStorage.getItem('learn2VideoData') || '{}'
        );
        let localCurrentXp = parseInt(localXp?.weeklyTotalXP || 0);
        this.showCurrentXp = {
          ...localXp,
          weeklyTotalXP: localCurrentXp > 0 ? localCurrentXp : 0,
        };
        this.cdr.detectChanges();
        localStorage.removeItem('videoLearn2EarnData');
        this.handleVideoPlayer();
        return;
      }
      if (refetchXP) {
        const result = await lastValueFrom(
          await this.learn2EarnService.getWeeklyUserXP(cohortId)
        );
        if (result?.unreadXP > 0) {
          const localXp = JSON.parse(
            localStorage.getItem('learn2VideoData') || '{}'
          );
          let localCurrentXp = videoPlayer
            ? parseInt(localXp?.weeklyTotalXP || 0)
            : parseInt(result?.weeklyTotalXP || 0) -
              parseInt(result?.unreadXP || 0);
          localStorage.removeItem('learn2VideoData');
          this.showCurrentXp = {
            ...result,
            weeklyTotalXP: localCurrentXp > 0 ? localCurrentXp : 0,
          };
          this.cdr.detectChanges();
          this.handleCheckForToolTip();
          if (!videoPlayer) {
            setTimeout(() => {
              const xpGainImage = this.xpGainImageRef?.nativeElement;
              const xpHeaderButton = this.xpHeaderButtonRef?.nativeElement;
              if (xpGainImage && xpHeaderButton) {
                this.renderer.addClass(xpGainImage, 'move-up-down');
                this.renderer.addClass(xpHeaderButton, 'xp-container-anime');
                this.showCurrentXp.weeklyTotalXP += Math.round(
                  parseInt(this.showCurrentXp?.unreadXP || 0)
                );
                this.cdr.detectChanges();
                this.handleCheckForToolTip();
                setTimeout(() => {
                  this.renderer.removeClass(xpGainImage, 'move-up-down');
                  this.renderer.removeClass(
                    xpHeaderButton,
                    'xp-container-anime'
                  );
                }, 3500);
              }
            }, 1500);
          }
        } else {
          this.showCurrentXp = result;
          this.cdr.detectChanges();
          this.handleCheckForToolTip();
        }
        this.learn2EarnService.setWeeklyXpData(this.showCurrentXp);
      } else {
        const exist = await this.learn2EarnService.getWeeklyXpData();
        if (exist) {
          this.showCurrentXp = exist;
          this.cdr.detectChanges();
          this.handleCheckForToolTip();
          if (videoPlayer) {
            localStorage.removeItem('videoLearn2EarnData');
            this.handleVideoPlayer();
          }
          return;
        }
        const result = await lastValueFrom(
          await this.learn2EarnService.getWeeklyUserXP(cohortId)
        );
        if (result?.unreadXP > 0) {
          const localXp = JSON.parse(
            localStorage.getItem('learn2VideoData') || '{}'
          );
          let localCurrentXp = videoPlayer
            ? parseInt(localXp?.weeklyTotalXP || 0)
            : parseInt(result?.weeklyTotalXP) - parseInt(result?.unreadXP);
          localStorage.removeItem('learn2VideoData');
          this.showCurrentXp = {
            ...result,
            weeklyTotalXP: localCurrentXp > 0 ? localCurrentXp : 0,
          };
          this.cdr.detectChanges();
          this.handleCheckForToolTip();
          if (!videoPlayer) {
            setTimeout(() => {
              const xpGainImage = this.xpGainImageRef?.nativeElement;
              const xpHeaderButton = this.xpHeaderButtonRef?.nativeElement;
              if (xpGainImage && xpHeaderButton) {
                this.renderer.addClass(xpGainImage, 'move-up-down');
                this.renderer.addClass(xpHeaderButton, 'xp-container-anime');
                this.showCurrentXp.weeklyTotalXP += Math.round(
                  parseInt(this.showCurrentXp?.unreadXP || 0)
                );
                this.cdr.detectChanges();
                this.handleCheckForToolTip();
                setTimeout(() => {
                  this.renderer.removeClass(xpGainImage, 'move-up-down');
                  this.renderer.removeClass(
                    xpHeaderButton,
                    'xp-container-anime'
                  );
                }, 3500);
              }
            }, 1500);
          }
        } else {
          this.showCurrentXp = result;
          this.cdr.detectChanges();
          this.handleCheckForToolTip();
        }
        this.learn2EarnService.setWeeklyXpData(this.showCurrentXp);
      }
      if (videoPlayer) {
        localStorage.removeItem('videoLearn2EarnData');
        this.handleVideoPlayer();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async checkShouldShowXPButton(
    refetchWeekly: boolean = true,
    forceRefetch: boolean = false
  ) {
    try {
      const cohortId =
        this.storageService.getValue('COHORT_ID', 'string') || '';
      try {
        const alreadyExist = await this.learn2EarnService.getConfigData();
        if (alreadyExist && !forceRefetch) {
          this.learn2EarnConfig = alreadyExist;
        } else {
          const localStorageData =
            this.learn2EarnService.checkLocalStorageConfig();
          let configData;
          if (!localStorageData) {
            configData = await lastValueFrom(
              this.learn2EarnService.getLearn2EarnConfig(cohortId)
            );
            localStorage.setItem(
              'learn2EarnLastFetch',
              new Date().toISOString()
            );
          } else {
            configData = localStorageData;
          }
          this.learn2EarnConfig = configData;
          this.learn2EarnService.setConfigData(this.learn2EarnConfig);
        }
        if (!this.learn2EarnConfig?.isLearnToEarnActive) {
          this.showXPButton = false;
          return;
        }
        if (this.isFromCommonHeader) {
          this.showXPButton = this.isValidURL();
          this.cdr.detectChanges();
        } else {
          this.showXPButton = true;
          this.cdr.detectChanges();
        }
        this.getWeeklyXp(refetchWeekly);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  isValidURL(): boolean {
    if (
      this.router.url.includes('/batches') ||
      this.router.url.includes('batches/study')
    ) {
      return true;
    }
    return false;
  }

  getWatchDurations(watchStats: any) {
    let watchDuration = 0;
    if (watchStats) {
      watchStats.forEach((stats: any) => {
        watchDuration += stats.endTimeTimestamp - stats.startTimeTimestamp;
      });
    }
    watchDuration = watchDuration / 1000;
    return watchDuration;
  }

  handleVideoPlayer = async () => {
    let newVideosStatsData = [];
    if (localStorage.getItem('watchStats')) {
      newVideosStatsData = JSON.parse(
        localStorage.getItem('watchStats') || '[]'
      );
    } else {
      newVideosStatsData =
        JSON.parse(localStorage.getItem('learn2EarnVideoStats') || '{}')
          ?.watchStats ?? [];
      newVideosStatsData = JSON.parse(newVideosStatsData ?? '[]');
    }
    let oldVideoStatsDuration = [];
    // @ts-ignore
    if (!window?.videoStats) {
      oldVideoStatsDuration = JSON.parse(
        localStorage.getItem('oldWatchStats') || '[]'
      );
    }
    const oldWatchTimeInSeconds = this.getWatchDurations(oldVideoStatsDuration);
    const newWatchTimeInSeconds = this.getWatchDurations(newVideosStatsData);
    const totalWatchTimeInMinutes =
      (oldWatchTimeInSeconds + newWatchTimeInSeconds) / 60;
    const videoPointsConfig =
      this?.learn2EarnConfig?.featureDetails?.VIDEO ?? 0;
    this.earnedXp = videoPointsConfig * Math.floor(totalWatchTimeInMinutes);
    if (this.earnedXp <= 0 || isNaN(this.earnedXp)) {
      return;
    }
    const cohortId = this.storageService.getValue('COHORT_ID', 'string') || '';
    let batchData = this.storageService.getValue('BATCH_DATA', 'json') || '';
    if (!batchData) {
      batchData = this.storageService.getValue('BATCH_DETAILS', 'json') || '';
    }
    const videoStats =
      this.storageService.getValue('learn2EarnVideoStats', 'json') || '';
    let isAnimationEnabled = await lastValueFrom(
      this.learn2EarnService.isAnimationEnabled(
        cohortId,
        batchData?._id,
        videoStats?.entryPoint,
        videoStats?.type
      )
    );
    if (!isAnimationEnabled?.isEnabled) {
      this.storageService.removeValue('learn2EarnVideoStats');
      this.storageService.removeValue('VIDEO_ID');
      return;
    }
    const userInfo = this.globalService.getUser().getValue() || {};
    let videoId =
      this.storageService.getValue('learn2EarnVideoStats', 'json')?.videoId ||
      '';
    if (!videoId) {
      videoId = this.storageService.getValue('VIDEO_ID', 'string');
    }
    this.firebaseAnalyticsService.logEvents(
      'l2e_animation',
      {
        user_id: userInfo?.id,
        batch_id: batchData?._id,
        cohort_id: cohortId,
        activity_id: videoId,
        number_of_XP: this.earnedXp,
        watch_time: oldWatchTimeInSeconds + newWatchTimeInSeconds,
        timestamp: new Date().getTime(),
        source: 'Web',
      },
      false,
      false,
      true,
      false,
      false
    );
    this.showAnimation = true;
    this.cdr.detectChanges();
    const xpGainImage = this.xpGainImageRef?.nativeElement;
    const xpHeaderButton = this.xpHeaderButtonRef?.nativeElement;
    //Call unread xp here for video-player
    const videoDetails = this.globalService.getVideoDetails();
    lastValueFrom(
      this.learn2EarnService.putLearn2EarnUnreadXpShown(
        cohortId,
        videoId ??
          videoDetails?.videoDetails?.id ??
          videoDetails?.videoDetails_id ??
          ''
      )
    );
    this.storageService.removeValue('learn2EarnVideoStats');
    this.storageService.removeValue('VIDEO_ID');
    setTimeout(() => {
      const xpGainImage = this.xpGainImageRef?.nativeElement;
      const xpHeaderButton = this.xpHeaderButtonRef?.nativeElement;
      if (xpGainImage && xpHeaderButton) {
        this.renderer.addClass(xpGainImage, 'move-up-down');
        this.renderer.addClass(xpHeaderButton, 'xp-container-anime');
        if (
          Math.round(this.showCurrentXp?.unreadXP) > Math.round(this.earnedXp)
        ) {
          this.earnedXp = this.earnedXp;
        }
        this.showCurrentXp.weeklyTotalXP += this.earnedXp; // Increment current XP by earned XP
        this.learn2EarnService.setWeeklyXpData(this.showCurrentXp);
        this.cdr.detectChanges();
        this.handleCheckForToolTip();
        setTimeout(() => {
          this.renderer.removeClass(xpGainImage, 'move-up-down');
          this.renderer.removeClass(xpHeaderButton, 'xp-container-anime');
        }, 1500);
      }
    }, 3500);
  };

  handleDrawerOpen() {
    const userInfo = this.globalService.getUser().getValue();
    this.firebaseAnalyticsService.logEvents(
      'l2e_clicks',
      {
        user_id: userInfo.id,
        page_name: this.learn2EarnService.getPageNameByRoute(this.router.url),
        click_text: 'xp_icon_click',
        click_type: 'icon',
      },
      false,
      false,
      true,
      false,
      false
    );
    this.showToolTip = false;
    this.disableToolTipCheck = true;
    const timeSeen = this.learn2EarnConfig.leaderboardUpdateNextDate;
    this.globalService.openLearnToEarnSideBar(true);
  }

  eventHandler = (event: MessageEvent<IFrameMessageProps>) => {
    if (
      event.data.action === IFrameActions.EARN_XP_DATA &&
      event.data.applicationType === ApplicationTypes.PW_LEARN_2_EARN
    ) {
      const eventData = JSON.parse(event?.data?.value);
      const points: any =
        eventData?.type === 'Reattempt'
          ? this?.learn2EarnConfig?.featureDetails?.REG_TEST_RE_ATTEMPT
          : this?.learn2EarnConfig?.featureDetails?.REG_TEST_FIRST_ATTEMPT;
      this.earnedXp = eventData?.correctQuestions * (points ?? 0);
      if (this.earnedXp <= 0 || isNaN(this.earnedXp)) {
        return;
      }
      this.showAnimation = true;
      const xpGainImage = this.xpGainImageRef?.nativeElement;
      const xpHeaderButton = this.xpHeaderButtonRef?.nativeElement;
      //Call unread xp here for test-series
      const activityId =
        this.activatedRoute.snapshot.paramMap.get('testId') || '';
      const cohortId =
        this.storageService.getValue('COHORT_ID', 'string') || '';
      lastValueFrom(
        this.learn2EarnService.putLearn2EarnUnreadXpShown(cohortId, activityId)
      );
      setTimeout(() => {
        if (xpGainImage && xpHeaderButton) {
          this.renderer.addClass(xpGainImage, 'move-up-down');
          this.renderer.addClass(xpHeaderButton, 'xp-container-anime');
          this.showCurrentXp.weeklyTotalXP =
            parseInt(this.showCurrentXp?.weeklyTotalXP) +
            parseInt(eventData?.correctQuestions) * (points ?? 0);
          this.cdr.detectChanges();
          this.handleCheckForToolTip();
          setTimeout(() => {
            this.renderer.removeClass(xpGainImage, 'move-up-down');
            this.renderer.removeClass(xpHeaderButton, 'xp-container-anime');
          }, 1500);
        }
      }, 3500);
    }
  };
}
