import { BatchService } from 'src/app/common/services/batch/batch.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { GlobalService } from '../../services/global/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { STORAGE_ENUM, StorageService } from '../../services/storage/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalObjectService } from '../../services/global-object/global-object.service';

import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

import {
  APP_STORE_URL,
  PLAY_STORE_URL,
} from '../../../constants/global-constant/global-constant.service';
import { ConfirmLogoutDialogComponent } from '../confirm-logout-dialog/confirm-logout-dialog.component';
import { Location } from '@angular/common';
import {
  APP_STORE_IMG,
  BOY_IMG,
  GIRL_IMG,
  PLAYSTORE_IMG,
  PW_LOGO,
  SCHOLAR_LOGO,
} from '../../../core/assets.location';
import { Exam, SaarthiService } from '../../services/saarthi/saarthi.service';
import { ExamModalComponent } from 'src/app/pages/saarthi/components/exam-modal/exam-modal.component';
import { CohortDialogComponent } from 'src/app/pages/batch/batchlist/cohort-dialog/cohort-dialog.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { CohortService } from '../../services/cohort/cohort.service';
import { ReferEarnData } from '../../services/cohort/cohort.type';
import { FORM_TRIGGERS } from 'src/app/constants/form.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input('userInfo') userInfo: any;
  @Output('saarthiData') saarthiData: EventEmitter<any> =
    new EventEmitter<any>();
  isSideNavOpen: boolean;
  JSBridge: any;
  mobileQuery: any;
  sideNavSub: Subscription;
  showSplash = true;
  options: AnimationOptions = {
    path: 'https://assets10.lottiefiles.com/private_files/lf30_xh5zawik.json',
  };
  backUrl: string;
  backUrlSubs: Subscription;
  isAuth: boolean;
  playstoreImg = PLAYSTORE_IMG;
  appstoreImg = APP_STORE_IMG;
  boyImg = BOY_IMG;
  girlImg = GIRL_IMG;
  isToken: Subscription;
  pwLogo = PW_LOGO;
  exams: Array<Exam> = [];
  openExamModalSub: Subscription;
  batchExam: string = '';
  cohortIcon: string = '';
  referEarnData: ReferEarnData;
  scholarLogo = SCHOLAR_LOGO;
  @ViewChild('batchDialog', { read: ElementRef })
  batchDialog!: ElementRef<any>;
  userSelectedExams: Array<string>;
  userSelectedExam: string = '';
  userDetails: any;
  userSubs: Subscription;
  isAuthSub: Subscription;
  currentCohort: string = '';
  cohortConfigSub: Subscription;
  isProfileEnabled: boolean = true;
  isMyPurchaseEnabled: boolean = true;
  isReferEarnEnable: boolean = true;
  shouldShowProfile: boolean = true;
  cameFromStudy: boolean;
  selectedClassSub: Subscription;
  selectedExamSub: Subscription;
  selectedExam: string = '';
  selectedClass: string = '';
  selectedSlot: string;
  userExam: Array<string> = [];
  userClass = '';
  availableSlots = [];
  saarthiId: any = [];
  private _mobileQueryListener: () => void;
  userInfoData: any;
  currentCohortId: string = '';
  isScholarEnabled: boolean = false;
  hideHeader$: BehaviorSubject<boolean>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private _globalService: GlobalService,
    public router: Router,
    private storageService: StorageService,
    public dialog: MatDialog,
    private globalObjectService: GlobalObjectService,
    private batchService: BatchService,
    private location: Location,
    private globalService: GlobalService,
    private saarthiService: SaarthiService,
    private cohortDialog: NgDialogAnimationService,
    private cohortService: CohortService,
    private activatedRoute: ActivatedRoute
  ) {
    this.userInfoData = this.globalService.getUser().getValue();
    this.mobileQuery = media.matchMedia('(max-width: 769px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.isAuthSub = this.globalService.isLoggedIn.subscribe((data) => {
      this.isAuth = data;
    });
    this.hideHeader$ = this.globalService.hideHeader$;
    // this.isToken = this.globalService.isLoggedIn.subscribe((res) => {
    //   this.isAuth = res;
    // });
    // this._globalService._accessToken$.subscribe((data) => {
    //   if (data.length > 0) {
    //     this.isAuth = true;
    //   }
    // });
    this.openExamModalSub = this.saarthiService.openModal$.subscribe((data) => {
      if (data) {
        this.examDetails();
      }
    });

    this.cameFromStudy =
      this.activatedRoute.snapshot.queryParamMap.get('came_from') === 'study' ||
      false;

    this.selectedExamSub = this.saarthiService.selectedExam$.subscribe(
      (res: any) => {
        this.selectedExam = res;
      }
    );
    this.selectedClassSub = this.saarthiService.selectedClass$.subscribe(
      (res: any) => {
        this.selectedClass = res;
      }
    );
  }

  async ngOnInit(): Promise<void> {
    if (this.userInfoData) {
      this.isScholarEnabled = this.userInfoData?.isScholar;
    }
    this.globalService.triggerAdmitCard(FORM_TRIGGERS.PROFILE);
    this.getSideNav();
    this.getUserBySubscription();
    // this.isSelected();
    // this.showExamOnHeader();
    this.getCohortName();
    this.shouldShowProfile =
      !this.activatedRoute.snapshot.queryParamMap.get('from_app');

    this.getReferAndData();
  }

  getCohortName() {
    this.cohortConfigSub = this.cohortService.cohortConfig$.subscribe((res) => {
      if (!res) return;
      this.currentCohortId = res?._id || '';
      this.currentCohort = res['name'] || '';
      this.isProfileEnabled = res?.webConfig?.isProfileEnabled;
      this.isMyPurchaseEnabled = res?.webConfig?.isMyPurchaseEnabled || true;

      this.isReferEarnEnable = res?.webConfig?.isReferAndEarnEnabled;
      this.cohortIcon = res?.webIcon || res?.mobIcon || '';
    });
  }

  goToPurchase() {
    this.router.navigateByUrl('/my-purchase?came_from=my_profile_click');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    // this.globalService.isLoggedIn.next(false);
    this.openExamModalSub.unsubscribe();
    this.isAuthSub.unsubscribe();
    if (this.cohortConfigSub) this.cohortConfigSub.unsubscribe();
  }

  showExamOnHeader() {
    const data = this.storageService.getValue('SAARTHI_PREFERENCES', 'json');

    if (data) {
      this.selectedClass = data.selectedClass;
      this.selectedExam = data.selectedExam;
      this.selectedSlot = data.selectedSlot;
      this.availableSlots = data.availableSlots;

      this.saarthiService.timeslot$.next(this.selectedSlot);

      this.saarthiService.slot$.next(this.availableSlots);
    } else {
      this.selectedExam = this.userInfo?.profileId?.exams[0];
      this.selectedSlot = '';
      this.saarthiService.timeslot$.next(this.selectedSlot);
    }
  }

  examDetails() {
    if (this.isAuth) {
      const dialogRef = this.dialog.open(ExamModalComponent, {
        disableClose:
          this.selectedExam.length > 0 && this.selectedClass.length > 0
            ? false
            : true,
        data: {
          selectedClass: this.selectedClass,
          selectedExam: this.selectedExam,
          selectedSlot: this.selectedSlot,
        },
        width: '400px',
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (!data || !data.class || !data.exam) {
        }
        if (data) {
          if (data.exam && data.class) {
            this.showExamOnHeader();
            this._globalService.showSnackBar('Updated Sucessfully ...');
          }
          this.saarthiData.emit(data);
        }
      });
    }
  }

  isSelected() {
    if (this.userExam.length === 1) {
      if (this.userExam.includes('BOARD_EXAM')) {
        this.userSelectedExam = 'Boards';
      } else if (this.userExam.includes('IIT-JEE')) {
        this.userSelectedExam = 'IIT JEE';
      } else {
        this.userSelectedExam = this.userExam[0];
      }
      this.batchService.setUserSelectedExam(this.userExam[0]);
    } else {
      if (this.userExam.includes('NEET')) {
        this.userSelectedExam = 'NEET';
        this.batchService.setUserSelectedExam('NEET');
      } else if (this.userExam.includes('IIT-JEE')) {
        this.userSelectedExam = 'IIT JEE';
        this.batchService.setUserSelectedExam('IIT-JEE');
      } else if (this.userExam.includes('NDA')) {
        this.userSelectedExam = 'NDA';
        this.batchService.setUserSelectedExam('NDA');
      }
    }
  }

  getUser() {
    let data = JSON.parse(this.storageService.getUser() || '{}');

    this.userExam = data.profileId?.exams || [];
    this.userClass = data.profileId?.class || '';

    this.isSelected();
  }

  getUserBySubscription() {
    this.userSubs = this.storageService.getUserAsBehaviourSubject().subscribe(
      (res: any) => {
        if (res && res.length > 0) {
          const user = JSON.parse(res);
          this.userExam = user.profileId?.exams || [];
          this.userClass = user.profileId?.class || '';
          this.isSelected();
        }
      },
      (err: any) => {
        this.getUser();
      }
    );
  }

  goTobatch(url: string) {
    if (url.includes('batches')) {
      this.router.navigate([`batches`]);
    } else if (url.includes('test-list')) {
      this.router.navigate([`test-series`]);
    } else if (url.includes('test-series')) {
      this.router.navigate([`dashboard`]);
    }
  }

  toggleSideNav() {
    if (this.isSideNavOpen) {
      this._globalService.openSideNav(this.isSideNavOpen);
    } else {
      this._globalService.openSideNav(!this.isSideNavOpen);
    }
  }

  getSideNav() {
    this.sideNavSub = this._globalService.isSideNavOpen().subscribe(
      (res) => {
        this.isSideNavOpen = res;
      },
      () => {
        console.log('Unable to get user menu value');
      }
    );
  }

  showMenuBtn() {
    return (
      this.router.url.endsWith('/dashboard') ||
      this.router.url.endsWith('/batches') ||
      this.router.url.endsWith('/test-tab') ||
      this.router.url.endsWith('/books-public') ||
      this.router.url.endsWith('/feeds') ||
      this.router.url.endsWith('/bookmarks') ||
      this.router.url.endsWith('/notifications') ||
      this.router.url.endsWith('/offers') ||
      this.router.url.endsWith('/contactus') ||
      this.router.url.endsWith('/aboutus') ||
      this.router.url.endsWith('/termsandconditions') ||
      !this.router.url.endsWith('/library') ||
      this.router.url.endsWith('/doubts-connect') ||
      this.router.url.endsWith('/test-series') ||
      this.router.url.endsWith('/refer-earn') ||
      this.router.url.endsWith('/wallet') ||
      this.router.url.endsWith('/saarthi') ||
      this.router.url.endsWith('/school-contact-program') ||
      this.router.url.endsWith('/study')
    );
  }

  notifClick() {
    this.router.navigate(['notifications']);
  }

  redirectToRefer() {
    this.router.navigate(['referral-new'], {
      queryParams: {
        source: 'batch_listing',
      },
    });
  }

  showLottie() {
    if (this.userInfo) {
      return (
        this.router.url.includes('/batches') &&
        this.userInfo['profileId']['wallet'] > 0
      );
    } else return false;
  }

  animationCreated(animationItem: AnimationItem): void {}

  openPlaystore(type: string) {
    if (type === 'android')
      this.globalObjectService.window!.open(PLAY_STORE_URL, '_system');
    else if (type === 'apple')
      this.globalObjectService.window!.open(APP_STORE_URL, '_system');
  }

  goToProfile() {
    this.globalService.triggerAdmitCard(FORM_TRIGGERS.PROFILE);
    this.router.navigate(['profile']);
  }

  handleLogout() {
    const dialogRef = this.dialog.open(ConfirmLogoutDialogComponent, {
      panelClass: 'test-submit-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.logoutUser();
      }
    });
  }

  logoutUser() {
    this._globalService.logoutUserGlobally('absolute', true);
    this._globalService.removeSessionStorage();
  }

  onClick() {
    //@ts-ignore
    if (window && window.JSBridge) {
      //@ts-ignore
      window.JSBridge.onWebBackPressed();
      return;
    }

    if (this.cameFromStudy) {
      this.router.navigate(['batches/study']);
      return;
    }
    if (!this.isAuth) {
      this.router.navigate(['']);
      return;
    }

    this.location.back();
  }

  openCohortModal() {
    this.globalService.setPreviousUrl$(this.router.url);
    this.router.navigateByUrl('cohort-selection');

    // const dialogRef = this.cohortDialog.open(CohortDialogComponent, {
    //   panelClass: 'cohort-dialog',
    //   position: { right: '0px' },
    //   disableClose: true,
    //   data: { openFrom: 'header' },
    //   animation: {
    //     to: 'left',
    //     incomingOptions: {
    //       keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
    //     },
    //     outgoingOptions: {
    //       keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
    //     },
    //   },
    // });
  }

  async getReferAndData() {
    try {
      const res = await lastValueFrom(this.cohortService.getReferAndEarnData());
      if (res && res.data) {
        this.referEarnData = new ReferEarnData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  goToReferEarn() {
    this.router.navigate(['/referral-new']);
  }

  goToScholar() {
    this.storageService.setSessionValue(
      STORAGE_ENUM.BATCH_SOURCE_URL,
      this.router.url,
      'string'
    );
    this.router.navigate(['/pw-scholars']);
  }
}
