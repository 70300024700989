import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PwSatService {
  private satPage$: BehaviorSubject<IFrameMessageProps[]> = new BehaviorSubject<
    IFrameMessageProps[]
  >([]);
  constructor(private storageService: StorageService) {}

  setScholarshipPageArray(data: IFrameMessageProps[]) {
    this.storageService.setScholarshipPageArray(data);
    this.satPage$.next(data);
  }

  get getScholarshipArray() {
    const data = this.storageService.getScholarshipPageArray() || [];
    if (data.length > 0) {
      this.setScholarshipPageArray(data);
    }
    return this.satPage$;
  }
  removeScholarshipPageArray() {
    this.storageService.removeScholarshipPageArray();
    this.satPage$.next([]);
  }
}

export enum IFrameActions {
  CHILD_DATA_STORAGE = 'CHILD_DATA_STORAGE',
  CHILD_NAVIGATION = 'CHILD_NAVIGATION',
  PARENT_NAVIAGTION = 'PARENT_NAVIAGTION',
  LOCALSTORAGE = 'LOCALSTORAGE',
  CHILD_NAVIGATION_BACK = 'CHILD_NAVIGATION_BACK',
  START_TEST = 'START_TEST',
  AFTER_SUBMIT = 'AFTER_SUBMIT',
  VIEW_RESULT = 'VIEW_RESULT',
  IFRAME_LOADED = 'IFRAME_LOADED',
}

export enum SAT_TEST_SERIES {
  TEST_SCHOLARSHIP_RESULT = 'TEST_SCHOLARSHIP_RESULT',
  LANDING_SCHOLARSHIP_RESULT = 'LANDING_SCHOLARSHIP_RESULT',
  TEST_SCHOLARSHIP_MOCK = 'TEST_SCHOLARSHIP_MOCK',
}

export enum ApplicationTypes {
  PW_SCHOLARSHIP = 'PW_SCHOLARSHIP',
  PW_TEST_SERIES = 'PW_TEST_SERIES',
}

export enum PW_SCHOLARSHIP_TEST_SERIES {
  SCHOLARSHIP = 'SCHOLARSHIP',
  TEST_SCHOLARSHIP = 'TEST_SCHOLARSHIP',
  TEST_SCHOLARSHIP_MOCK = 'TEST_SCHOLARSHIP_MOCK',
  SCHOLARSHIP_RESULT = 'SCHOLARSHIP_RESULT',
  TEST_SCHOLARSHIP_RESULT = 'TEST_SCHOLARSHIP_RESULT',
  LANDING_SCHOLARSHIP_RESULT = 'LANDING_SCHOLARSHIP_RESULT',
  USER_SCHOLARSHIP_REGISTERED_STATUS = 'REGISTERED',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: any;
  applicationType: ApplicationTypes;
  newFlag?: boolean;
}
