import {
  Component,
  EventEmitter,
  Inject,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { STUDY_SIDEBAR_CLOSE_ICON } from 'src/app/core/assets.location';
import { AllPurchasedBatches } from '../../../batch-overview/batch-overview.model';
import { ScheduleKey } from '../schedule-class/schedule-class.component';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { STUDY_PAGE_EVENT } from 'src/app/core/analytics-events';
@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss'],
})
export class ScheduleDialogComponent implements OnInit, OnChanges {
  activeSection: string;
  title: string;
  itemList: any[];
  selectedItem: any;
  key: string;
  close_icon = STUDY_SIDEBAR_CLOSE_ICON;
  paginate: boolean;
  paginationEnabled: boolean = false;
  @Output() handleLoadMore = new EventEmitter<boolean>();
  paidBatchList: AllPurchasedBatches[] = [];
  freeBatchList: AllPurchasedBatches[] = [];

  constructor(
    private dialog: MatDialogRef<ScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      itemList: AllPurchasedBatches[];
      selectedItem: any;
      key: ScheduleKey;
      paginate: boolean;
      paginationEnabled: boolean;
    },
    private firebaseService: FirebaseAnalyticsService
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.itemList = this.data.itemList;
    this.selectedItem = this.data.selectedItem;
    this.key = this.data.key;
    this.paginate = this.data.paginate || false;
    this.paginationEnabled = this.data.paginationEnabled || false;
    this.paidBatchList = this.itemList.filter((item) => item.isPaid);
    this.freeBatchList = this.itemList.filter((item) => !item.isPaid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.itemList) {
      this.itemList = changes.itemList.currentValue;
    }
    if (changes && changes.paginate) {
      this.paginate = changes.paginate.currentValue;
    }
  }

  handleSelectedItem(item: any): void {
    this.dialog.close({
      data: item,
    });
  }

  closeModal(): void {
    this.dialog.close();
  }

  loadmoreContent() {
    this.handleLoadMore.emit(this.paginate);
  }
}
