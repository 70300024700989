import { Injectable } from '@angular/core';

export const APP_VERSION = '8.1.6';
let mainSlug: string = '';

@Injectable({
  providedIn: 'root',
})
export class GlobalConstantService {
  constructor() {}
}

export let PLAY_STORE_URL = 'https://bit.ly/2SHIPW6';
export const APP_STORE_URL =
  'https://apps.apple.com/in/app/physics-wallah/id1641443555';
export let META_URL = 'https://www.facebook.com/physicswallah';
export let YOU_TUBE_URL = 'https://www.youtube.com/physicswallah';
export let TELEGRAM_URL = 'https://t.me/Physics_Wallah_Official_Channel';
export let INSTAGRAM_URL = 'https://www.instagram.com/physicswallah/';
export const MAIN_PROGRAM = '5eb3b1017b1fb86475ec30eb';
export const Q_BANK_PROGRAM_ID = '61e176c9da50b57ef7b83483'; // dev 61e176c9da50b57ef7b83483
export let MAIN_PROGRAM_SLUG = 'complete-programe-main-droper--270029'; //'complete-programe-main-droper--681152'; //dev complete-programe-main-droper--681152

export function setSlugFromNetwork(slug: string) {
  MAIN_PROGRAM_SLUG = slug;
}
