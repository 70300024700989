<div>
  <div class="pl-6 pr-5 py-2.5 flex items-end justify-end">
    <mat-icon (click)="closeStreakModal()" class="cursor-pointer close-icon"
      >close</mat-icon
    >
  </div>
  <div class="streak-container" [style.height]="iframeHeight">
    <article [hidden]="isLoaded">
      <iframe
        #streakIframe
        class="responsive-iframe"
        id="streakIframe"
        (load)="checkLoaded($event, streakIframe)"
        [src]="url | safe : 'resourceUrl'"
        [style.height]="iframeHeight"
        allow="accelerometer; autoplay;fullscreen; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </article>
  </div>
</div>
