import { Injectable } from '@angular/core';
import { PPApiOptions } from '../../api/api.type';
import { PPApiService } from '../../api/api.service';
import { catchError, map, tap } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import { AppUrlService } from '../../app-url/app-url.service';

@Injectable({
  providedIn: 'root',
})
export class StreakService {
  constructor(
    private apiService: PPApiService,
    private appUrlService: AppUrlService
  ) {}
  getShowStreakInfo() {
    const url = this.appUrlService.SHOW_STREAK_UNLEASH_INFO_URL();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }
  getStreakInfo() {
    const url = this.appUrlService.STREAK_INFO_URL();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }
}
export enum IFrameActions {
  START_STREAK = 'START_STREAK',
  STREAK_INSTRUCTIONS = 'STREAK_INSTRUCTIONS',
  SHARE_ICON_CLICK = 'SHARE_ICON_CLICK',
}

export enum ApplicationTypes {
  PW_STREAK = 'PW_STREAK',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: any;
  applicationType: ApplicationTypes;
}

export type StreakInfoType = {
  firstTimeUser: boolean;
  graceTimeLeft: number;
  isOnGrace: boolean;
  streakCount: number;
  todaysWatchTime: number;
  showGraceModal?: boolean;
  graceModalCount: number;
};
