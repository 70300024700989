<mat-card class="card bg-white">
  <div class="flex flex-wrap overflow-hidden justify-between items-center p-4">
    <div
      class="my-1 px-1 w-8/12 sm:w-10/12 overflow-hidden flex justify-start items-center"
    >
      <!-- Column Content -->
      <div class="flex flex-wrap -mx-1 overflow-hidden">
        <div class="w-full overflow-hidden mb-4">
          <h3
            [matTooltipDisabled]="quiz.test.name.length < 55"
            [matTooltip]="quiz.test.name"
            class="text-left heading-4 card-title max-w-full"
          >
            {{ quiz.test.name }}
          </h3>
        </div>

        <div class="w-full overflow-hidden flex gap-2 card-subtitle">
          <img [src]="quizImg" class="dpp-quiz-icon" alt="quiz-icon" />
          <span class="text-left pl-2 medium"
            >{{ quiz?.test?.totalQuestions }} Questions</span
          >
          |
          <span class="text-left medium">
            {{ quiz?.test?.totalMarks }} Marks</span
          >
          <span *ngIf="earnUptoXp > 0 && !isSubjective"> | </span>
          <div
            *ngIf="earnUptoXp > 0 && !isSubjective"
            class="text-left medium flex justify-center items-center gap-1 hidden only-large-screen"
          >
            <div>
              <span>Earn upto </span>
              <span class="point-text">{{ earnUptoXp }}</span>
            </div>
            <img [src]="xpIcon" height="18px" width="18px" />
          </div>
        </div>
        <div
          *ngIf="earnUptoXp > 0 && !isSubjective"
          class="pt-4 text-left medium flex justify-center items-center gap-1 hidden only-mobile"
        >
          <div>
            <span>Earn upto </span>
            <span class="point-text">{{ earnUptoXp }}</span>
          </div>
          <img [src]="xpIcon" height="18px" width="18px" />
        </div>
      </div>
    </div>

    <div
      *ngIf="!isSubjective"
      class="my-1 px-1 w-2/12 sm:w-1/12 overflow-hidden flex flex-col justify-center items-center gap-1 min-w-fit"
    >
      <div class="w-8 h-8 rounded-full flex flex-col">
        <img
          [src]="
            quiz.tag === iconType.START
              ? startImg
              : quiz.tag === iconType.RESUME
              ? resumeImg
              : replayImg
          "
        />
      </div>
      <span
        class="bold-1 body-2 flex justify-center"
        [ngClass]="{
          startText: quiz.tag === iconType.START,
          resumeText: quiz.tag === iconType.RESUME,
          reattemptText: quiz.tag === iconType.REATTEMPT
        }"
        >{{ quiz.tag }}</span
      >
    </div>
  </div>
</mat-card>
