import { Injectable } from '@angular/core';
import { PPApiService } from '../../api/api.service';
import { StorageService } from '../storage/storage.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import {
  Details,
  ExerciseFeedbackRootObject,
  InstructionRootObject,
  QuestionsResponseRootObject,
  ResponseFileRootObject,
  Section,
  StudentTestMapping,
  TestDetailsRootObject,
  TestFeedback,
  TestInstructionData,
  TestInstructionRootObject,
  TestListParticularObject,
  TestListRootObject,
  TestRootObject,
  TestSeriesRootObject,
  TestStatusRootObject,
} from '../../../pages/tests/tests.model';
import { handleError } from '../error-handler/error-handler.service';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  shareReplay,
  tap,
} from 'rxjs';
import {
  ComparativeDataRootObject,
  RankRootObject,
  ResultRootObject,
} from '../../../pages/tests/test-results/result-model';
import { SolutionRootObject } from '../../../pages/tests/test-results/view-solution/viewsolution.model';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import { SubjectRootObject } from '../../../pages/tests/qBank.model';
import { retry } from 'rxjs/operators';
import { AppUrlService } from '../../app-url/app-url.service';
import { OfferDetailObject } from 'src/app/pages/offers/offers.model';

@Injectable({
  providedIn: 'root',
})
export class TestSeriesService {
  TEST_SERIES: string = 'TEST_SERIES';
  TEST_INSTRUCTION: string = 'TEST_INSTRUCTION';
  TEST_DATA: string = 'TEST_DATA';
  TEST_SOLUTION: string = 'TEST_SOLUTION';
  ACTIVE_TEST_CATEGORY = 'ACTIVE_TEST_CATEGORY';
  TEST_LISTING: string = 'testListing';
  QBANK_LISTING: string = 'qBankLisiting';
  TESTS_LIST = 'testsInCat';
  TAB_SWITCH = 'TEST_TAB';

  public isTestRunning = false;
  public isOfflineMode = true;
  public isOffline = false;
  _testData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public _testRunning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  _warningData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _infoData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _mobileGateOptionalData$: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  constructor(
    private apiService: PPApiService,
    private storage: StorageService,
    private appURlService: AppUrlService
  ) {}

  setTestSolution(data: any) {
    this.storage.setValue(this.TEST_SOLUTION, data, 'json');
  }
  getTestSolution() {
    return this.storage.getValue(this.TEST_SOLUTION, 'json');
  }
  removeTestSolution() {
    this.storage.removeValue(this.TEST_SOLUTION);
  }
  setTestSeries(test: any) {
    this.storage.setValue(this.TEST_SERIES, test, 'json');
  }

  getTestSeries() {
    return this.storage.getValue(this.TEST_SERIES, 'json');
  }

  removeTestSeries() {
    this.storage.removeValue(this.TEST_SERIES);
    //this.storage.setValue(this.TEST_SERIRES, { },'json')
  }
  setTabSwitch(data: any) {
    this.storage.setValue(this.TAB_SWITCH, data, 'string');
  }
  getTabSwitch() {
    return this.storage.getValue(this.TAB_SWITCH, 'string');
  }
  removeTabSwitch() {
    this.storage.removeValue(this.TAB_SWITCH);
  }

  setListingToStorage(data: string) {
    return this.storage.setValue(this.TEST_LISTING, data, 'string');
  }

  getListingFromStorage() {
    return this.storage.getValue(this.TEST_LISTING, 'json');
  }

  removeBatchListingFromStorage() {
    return this.storage.removeValue(this.TEST_LISTING);
  }

  setQBankLisitng(data: any) {
    return this.storage.setValue(this.QBANK_LISTING, data, 'string');
  }

  getQBankLisitng() {
    return this.storage.getValue(this.QBANK_LISTING, 'json');
  }

  removeQBankLisitng() {
    return this.storage.removeValue(this.QBANK_LISTING);
  }

  setTestRunning(value: any) {
    this.isTestRunning = value;
    this._testRunning$.next(value);
    this.storage.setValue(this.TEST_DATA, value, 'json');
  }

  getTestRunning() {
    const testRunning = this.storage.getValue(this.TEST_DATA, 'string');
    if (testRunning) {
      this.setTestRunning(JSON.parse(testRunning));
    }
    return this._testRunning$;
  }

  getTestCategory(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/test-categories`,
      params: new PPApiParams()
        .append('programId', query.programId)
        .append('page', query.page),
    };
    return this.apiService.get<TestSeriesRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getSearchTestCategory(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/test-categories`,
      params: new PPApiParams()
        .append('programId', query.programId)
        .append('name', query.name),
    };
    return this.apiService.get<TestSeriesRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  //v2/packages/detail?program=:programID&type='nested'&testCategoryId=testSeriesId
  getTestCategoryDetails(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/packages/detail`,
      params: new PPApiParams()
        .append('program', query.programId)
        .append('type', 'nested')
        .append('testCategoryId', query.testCategoryId),
    };
    return this.apiService.get<OfferDetailObject>(options).pipe(
      map((res) => res),
      tap(),
      catchError(handleError)
    );
  }

  //v2/test-categories/60dee40e9fa0993f23ed61dd/tests?page=1
  getTestList(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/test-categories/${query.testId}/tests`,
      params: new PPApiParams().append('page', query.page),
    };
    return this.apiService.get<TestListRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  //v2/tests/6113b7106e25fa05cff3cf69/get-test-status
  getTestStatus(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/tests/${query.testListId}/get-test-status`,
    };
    return this.apiService.get<TestStatusRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  // v1/tests/:mappingId/submit-subjective-test
  submitFile(query: any, data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/tests/${query.mappingId}/submit-subjective-test`,
    };
    return this.apiService.post({ responseFiles: data }, options).pipe(
      map((res: any) => res),
      catchError(handleError)
    );
  }

  getSubmitFileResponse(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/tests/${query.mappingId}/subjective-responses`,
    };
    return this.apiService.get<ResponseFileRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  //v2/test-categories/6067117ccd9bba4afd12cb11/tests/60b893e6f24aa30495d503bc/instructions
  getTestInstructions(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/test-categories/${query.testCatId}/tests/${query.testListId}/instructions`,
    };
    return this.apiService.get<TestInstructionRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getQuestionReportTags() {
    const options: PPApiOptions = {
      apiPath: `v1/organizations/${ORGANIZATION_ID}/question-report-tags`,
    };
    return this.apiService.get<QuestionReportTagRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  setInstructionsData(data: TestInstructionData) {
    this.storage.setValue(this.TEST_INSTRUCTION, data, 'json');
  }

  getInstructionData() {
    return this.storage.getValue(this.TEST_INSTRUCTION, 'json');
  }

  // v2/tests/611a7f6ae3d6641ef2b1aa7b/start-test?mode=nested
  startTest(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/tests/${query.testId}/start-test`,
      params: new PPApiParams().append('mode', 'nested').appendAll(query),
    };
    return this.apiService.get<TestRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  startTestv3(query: {
    testId: string;
    testSource: string;
    type: string;
    batchId?: string;
    batchSubjectId?: string;
  }) {
    query = query || {};
    const options: PPApiOptions = {
      apiPath: `v3/test-service/tests/${query.testId}/start-test`,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<TestRootObject>(options).pipe(
      map((res) => {
        return {
          data: new V3ToV2ExerciseDetails(res.data),
          success: res.success,
        };
      }),
      tap((data) => {
        console.log(data);
      }),
      catchError(handleError)
    );
  }

  startTestSubjective(query: any, testId: string) {
    const options: PPApiOptions = {
      apiPath: `v2/tests/${testId}/start-test`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<TestRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  ///v1/batches/jain-362180/jee-pattern-test-1-regular---352648/start-test?mode=nested
  testAccessibilityCheck(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/batches/${query.batchId}/${query.testId}/start-test`,
      params: new PPApiParams().append('mode', 'nested'),
    };
    return this.apiService.get<TestRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  //https://api-dev.penpencil.xyz/v1/tests/61d302354ef0405fea783d00/submit-test
  submitTest(testMappingId: string, submitData: QuestionsResponseRootObject) {
    const options: PPApiOptions = {
      // apiPath: `v1/tests/${id}/submit-test`,
      apiPath: `v3/test-service/tests/mapping/${testMappingId}/submit-test`,
    };
    return this.apiService.post(submitData, options).pipe(
      map((res) => res),
      tap(),
      catchError(handleError)
    );
  }

  getTestResult(testId: string, params: any = {}) {
    const options: PPApiOptions = {
      apiPath: `v3/test-service/tests/${testId}/my-result`,
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService.get<ResultRootObject>(options).pipe(
      map((res) => {
        return {
          ...res.data.yourPerformance,
          test: res.data.test,
          testStudentMappingId: res?.data?.testStudentMapping?._id,
        };
      }),
      catchError(handleError)
    );
  }

  getTestInstructionsData(testId: string, params: any = {}) {
    const options: PPApiOptions = {
      apiPath: `v3/test-service/tests/${testId}/instructions`,
    };
    return this.apiService.get<InstructionRootObject>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  getResultRank(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/tests/${query.testId}/${query.resultId}/get-result-rank`,
    };
    return this.apiService.get<RankRootObject>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  //v1/tests/5f81d246c5b84e0aedcb9c2c/comparative-analysis
  getComparativeAnalysis(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/tests/${query.testId}/comparative-analysis`,
    };
    return this.apiService.get<ComparativeDataRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getSoultions(query: any) {
    const options: PPApiOptions = {
      apiPath: `v3/test-service/tests/mapping/${query.testStudentMappingId}/preview-test`,
    };
    return this.apiService.get<SolutionRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v2/tests/5f04662253848e0011ed5aca/mapping/61e46dc2da50b57ef7b859f5/pause
  pauseTest(testId: any, testMappingId: any, submitData: any) {
    const options: PPApiOptions = {
      // apiPath: `v2/tests/${testId}/mapping/${testMappingId}/pause`,
      apiPath: `v3/test-service/tests/mapping/${testMappingId}/submit-test`,
    };
    return this.apiService.post(submitData, options).pipe(
      map((res) => res),
      tap(),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v2/programs/5f59d787bba8fc15f36db0ab/subjects?page=1&isSpecial=true

  getSubjectsCategory(programId: string, query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/${programId}/subjects`,
      params: new PPApiParams()
        .append('page', query.page)
        .append('isSpecial', true),
    };
    return this.apiService.get<SubjectRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v2/packages/detail?program=5f59d787bba8fc15f36db0ab&type=nested&subjectId=607428701e3c470948aa9780

  getQbankSubjectDetails(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/packages/detail`,
      params: new PPApiParams()
        .append('program', query.programId)
        .append('subjectId', query.subjectId),
    };
    return this.apiService.get<OfferDetailObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      retry(1),
      catchError(handleError)
    );
  }

  getTestDetails(testId: string) {
    const options: PPApiOptions = {
      apiPath: this.appURlService.GET_TEST_DETAILS(testId),
    };

    return this.apiService.get<TestDetailsRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }
  getParticularTestDetails(testId: string) {
    const options: PPApiOptions = {
      apiPath: this.appURlService.GET_TEST(testId),
    };

    return this.apiService.get<TestListParticularObject>(options).pipe(
      map((res) => res),
      shareReplay(),
      catchError(handleError)
    );
  }

  setTestList(testList: any) {
    this.storage.setValue(this.TESTS_LIST, testList, 'json');
  }

  getTestsList() {
    return this.storage.getValue(this.TESTS_LIST, 'json');
  }

  removeTestsList() {
    return this.storage.removeValue(this.TESTS_LIST);
  }

  /*** Frontend logic start ***/

  /*** Frontend logic end ***/

  setGateOptional(data: any) {
    this._mobileGateOptionalData$.next(data);
  }
  get getGateOptional() {
    return this._mobileGateOptionalData$;
  }

  getFeedbackStatus(testId: string) {
    const options: PPApiOptions = {
      apiPath: this.appURlService.GET_TEST_FEEDBACK(),
      params: new PPApiParams().append('testId', testId),
    };

    return this.apiService.get<ExerciseFeedbackRootObject>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  getFeedbackRatingTags() {
    const options: PPApiOptions = {
      apiPath: this.appURlService.GET_TEST_FEEDBACK_RATING_TAGS(),
    };

    return this.apiService.get<TestDetailsRootObject>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  submitExerciseFeedback(data: TestFeedback) {
    const options: PPApiOptions = {
      apiPath: this.appURlService.GET_TEST_FEEDBACK(),
    };
    return this.apiService
      .post<ExerciseFeedbackRootObject>(data, options)
      .pipe(catchError(handleError));
  }

  getTestInstructionsV3(testId: string) {
    const options: PPApiOptions = {
      apiPath: `v3/test-service/tests/${testId}/instructions`,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  //  npf event

  testNpfExploreApi(payload: unknown) {
    const url = this.appURlService.TEST_NPF_EXPLORE();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post(payload, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async testNpfExplore(payload: unknown) {
    try {
      const res = await lastValueFrom(this.testNpfExploreApi(payload));
    } catch (error) {
      console.error(error);
    }
  }
}

/**
 * Question report Tag interface
 */
export class QuestionReportTag {
  name: string;
  isActive: boolean;

  constructor(tag: any) {
    this.name = tag.name || '';
    this.isActive = false;
  }
}

export class QuestionReportTagRootObject {
  success: boolean;
  data: QuestionReportTag[];
}

export interface DialogInfo {
  title: string;
  description: string;
  icon?: string;
}

class V3ToV2ExerciseDetails {
  sections: Section[];
  details: Details;

  constructor(data: any) {
    const _data = data;
    if (data.sections && data.sections.length)
      this.sections = data.sections.map((section: any) => new Section(section));
    else this.sections = [];
    this.details = new Details(_data);
  }
}

export interface TestInstruction {
  _id: string;
  name: string;
  type: string;
  maxDuration: number;
  totalMarks: number;
  totalQuestions: number;
  multiGeneralInstructions: MultiGeneralInstructions;
  multiTestInstructions: MultiTestInstructions;
  config: Config;
  languageCodes: LanguageCode[];
  residence: Residence;
  tag2: string;
  isSubjective: boolean;
}

export interface MultiGeneralInstructions {
  en: string;
}

export interface MultiTestInstructions {
  en: string;
}

export interface Config {
  isScientificCalculatorEnabled: boolean;
  proctoring: boolean;
}

export interface Residence {}

export interface LanguageCode {
  language: string;
  isSelected: boolean;
  code: string;
}
