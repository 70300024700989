<div *ngIf="isXpGainBoxVisible">
  <img
    [src]="xpIcon"
    class="coin-animate-1 rounded-[50%]"
    height="28px"
    width="24px"
  />
  <img
    [src]="xpIcon"
    class="coin-animate-2 rounded-[50%]"
    height="28px"
    width="24px"
  />
  <img
    [src]="xpIcon"
    class="coin-animate-3 rounded-[50%]"
    height="28px"
    width="24px"
  />
  <img
    [src]="xpIcon"
    class="coin-animate-4 rounded-[50%]"
    height="28px"
    width="24px"
  />
  <img
    [src]="xpIcon"
    class="coin-animate-5 rounded-[50%]"
    height="28px"
    width="24px"
  />
</div>
<div class="bg-[#000000] xp-gain-box" #xpGainBox>
  <div class="flex gap-[10px] w-full h-full ml-4 xp-gain-inner">
    <img [src]="xpRaysIcon" class="absolute rays-image z-[99]" />
    <img [src]="xpShineIcon" />
    <div class="flex items-center">
      <div class="flex flex-col">
        <span class="text-[18px] text-[#FFFFFF] font-[600] text-class"
          >Congratulations!!</span
        >
        <span
          *ngIf="isFromVideo !== true"
          class="text-[14px] text-[#FFFFFF] font-[600] text-class text-class-small"
        >
          You earned {{ earnedXp }} XP by completing the test!
        </span>
        <span
          *ngIf="isFromVideo === true"
          class="text-[14px] text-[#FFFFFF] font-[600] text-class text-class-small"
        >
          You earned {{ earnedXp }} XP by watching the video!
        </span>
      </div>
    </div>
  </div>
</div>
