import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TestSeriesNewService {
  private testSeriesPage$: BehaviorSubject<IFrameMessageProps[]> =
    new BehaviorSubject<IFrameMessageProps[]>([]);
  constructor(private storageService: StorageService) {}

  setTestSeriesPageArray(data: IFrameMessageProps[]) {
    this.testSeriesPage$.next(data);
    this.setTestSeriesPageArrayToLocal(data);
  }

  get getTestSeriesPageArray() {
    return this.testSeriesPage$;
  }

  removeCentrePageArray() {
    this.testSeriesPage$.next([]);
  }

  setTestSeriesPageArrayToLocal(value: any) {
    this.storageService.setValue('TEST_SERIES_URL', value, 'json');
  }
  getTestSeriesPageArrayToLocal() {
    return this.storageService.getValue('TEST_SERIES_URL', 'json');
  }
  removeTestSeriesPageArrayToLocal() {
    return this.storageService.removeValue('TEST_SERIES_URL');
  }

  setTestLocal(data: any) {
    return this.storageService.setValue('TEST_DETAIL', data, 'string');
  }
  getTestLocal() {
    return this.storageService.getValue('TEST_DETAIL', 'json');
  }
  removeTestLocal() {
    return this.storageService.removeValue('TEST_DETAIL');
  }
}

export enum IFrameActions {
  CHILD_NAVIGATION = 'CHILD_NAVIGATION',
  CHILD_NAVIGATION_BACK = 'CHILD_NAVIGATION_BACK',
  PARENT_NAVIAGTION = 'PARENT_NAVIAGTION',
  LOCALSTORAGE = 'LOCALSTORAGE',
  SET_USER = 'SET_USER',
  START_TEST = 'START_TEST',
  BUY_NOW = 'BUY_NOW',
  PLAY_VIDEO = 'PLAY_VIDEO',
  TAB_SWITCH = 'TAB_SWITCH',
  GO_TO_LISTING = 'GO_TO_LISTING',
  TEST_BACK = 'TEST_BACK',
  AFTER_SUBMIT = 'AFTER_SUBMIT',
  GO_TO_SOLUTION = 'GO_TO_SOLUTION',
  GO_TO_SUBJECTIVE = 'GO_TO_SUBJECTIVE',
  GO_TO_OBJECTIVE = 'GO_TO_OBJECTIVE',
  GO_TO_RESULT = 'GO_TO_RESULT',
  REATTEMPT_TEST = 'REATTEMPT_TEST',
  GO_TO_ATTACHMENTS = 'GO_TO_ATTACHMENTS',
  UPDATE_USER = 'UPDATE_USER',
  VALIDATE_TOKEN = 'VALIDATE_TOKEN',
  REDIRECT_ORDER_SUMMARY = 'REDIRECT_ORDER_SUMMARY',
  SET_SAARTHI = 'SET_SAARTHI',
  CHILD_DATA_STORAGE = 'CHILD_DATA_STORAGE',
  IFRAME_LOADED = 'IFRAME_LOADED',
  CHAT_NAVIGATION = 'CHAT_NAVIGATION',
  TEST_PASS_PAY = 'TEST_PASS_PAY',
  REPLACE_LAST_URL = 'REPLACE_LAST_URL',
  PASS_DETAILS = 'PASS_DETAILS',
  SELECTED_FILTER = 'SELECTED_FILTER',
  ORG_DATA = 'ORG_DATA',
  OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL',
  SHOW_SUBJECTIVE_LOADING_SCREEN = 'SHOW_SUBJECTIVE_LOADING_SCREEN',
  PLAN_BUY_NOW_CLICK = 'PLAN_BUY_NOW_CLICK',
  CLUBBING_PLAN_TABLE = 'CLUBBING_PLAN_TABLE',
  BATCH_PLUS_DATA = 'BATCH_PLUS_DATA',
  BATCH_DETAILS_PLAN_DATA = 'BATCH_DETAILS_PLAN_DATA',
  GO_TO_VIDEO_SOLUTION = 'GO_TO_VIDEO_SOLUTION',
  GO_TO_AI_EVALUATION = 'GO_TO_AI_EVALUATION',
  TEST_SERIES_FORM_CONTINUE_CLICK = 'TEST_SERIES_FORM_CONTINUE_CLICK',
}

export enum ApplicationTypes {
  PW_CENTRES = 'PW_CENTRES',
  PW_TEST_SERIES = 'PW_TEST_SERIES',
  PW_JOB_ALERTS = 'PW_JOB_ALERTS',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: any;
  applicationType: ApplicationTypes;
}
